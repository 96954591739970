
// import axios from 'axios';
import http from '../services/httpService';
// import { debug, error } from '../services/logger';

// const BASE_URL = process.env.REACT_APP_API_URL;
// BASE_URL in .env files and then grabed in /services/httpService.js
// You're using PUT, and the second argument in a PUT request using Axios is the request body (data sent to the server). 
// So if you want to pass DATA to the backend, it should be in an object

////// USERS
export const fetchUsers = async () => {
    const {data} = await http.get(`/users`); //other option  await http.get(`${BASE_URL}/users`);
    // console.log('API > fetchUsers > data = ', data);

    const { success, data: users, message } = data;
    if (!success) {
        throw new Error(message || 'Failed to fetch users');
    }

    return users; // array of users 
};

export const putUser = async (userEditInfo) => {
    // console.log('API.js | putUser | userEditInfo', userEditInfo)
    const data = await http.put(`/me/edit`, userEditInfo)
    return data;
};

////// ME
export const postUserAva = async (fileP) => {
    // console.log('API.js | postUserAva | fileP = ', fileP)
    const { data } = await http.post(`/me/useravaurl`, fileP);
    return data;
};

export const fetchUser = async () => {
    try {
        const { data } = await http.get(`/me`);
        // console.log("API.js | fetchUser | data = ", data)
        return data.data;
    } catch (error) {
        console.error('API.js | fetchUser | Error fetching user:', error);
    }
};

export const fetchUserSettings = async () => {
    try {
        // console.log('API.js | fetchUserSettings evoked!')
        const { data } = await http.get(`/me/settings`);
        // console.log('API.js | fetchUserSettings | data = ', data);
        return data;
    } catch (error) {
        console.error('API.js | fetchUserSettings | Error fetching user settings:', error);
        // Optionally, you could return a default value or rethrow the error:
        // return null; 
        throw new Error('Failed to fetch user settings');
    }
};

export const changeEmail = async (args) => {
    // console.log('API.js > changeEmail > args', args); //{email: 'test2@gmail.com', id: 53}

    try {
        const response = await http.put('/me/change-email', args);
        // console.log('API.js > changeEmail > response.data = ', response.data)
        const { success, message } = response.data;
        return { success, message };

    } catch (error) {
        console.error('API.js > changeEmail > error:', error);

        // Try to extract a known backend error message
        const fallbackMessage = 'An unexpected error occurred while changing email.';
        const message = error?.response?.data?.message || fallbackMessage;

        return {
            success: false,
            message
        };
    }
};

export const addFavorite = async (catId) => {
    try {
        console.log('API.js | addFavorite | catId: ', catId)
        const { data } = await http.post(`/me/favorite`, { catId });
        // console.log('API.js | addFavorite | data = ', data);
        return data;
    } catch (error) {
        console.error('API.js | addFavorite | Error fetching user settings:', error);
        // Optionally, you could return a default value or rethrow the error:
        // return null; 
        throw new Error('Failed addFavorite');
    }
};


export const sendToAI = async (file) => {
    try {
        const formData = new FormData();
        formData.append('image', file);
        console.log(file)
        const response = await http.post(`/uploadToAi`, formData);
        // console.log( 'api.js response = ', response)
        // return response.data;

        // const response = await fetch('/uploadToAi', {
        //     method: 'POST',
        //     body: formData
        // });

        if (response.status !== 200) {
            throw new Error(`Failed to process the image. Status: ${response.status}`);
        }

        const result = await response.data;
        console.log('Response from AI, result:', result)
        return result;
    } catch (error) {
        console.error('Error sending image to AI:', error);
        throw error;
    }
};



export const recognizePed = async (file) => {
    console.log('recognizePed', file);
    try {
        // Create a FormData object and append the file
        const formData = new FormData();
        formData.append('file', file);

        // Perform a POST request with FormData
        const response = await http.post('/recognize', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set content type
            },
        });

        // console.log('api.js response.data = ', response.data.recognizedText);

        return response.data.recognizedText;
    } catch (error) {
        console.error('Error in recognizePed:', error);

        if (error.response) {
            console.error('Response error:', error.response.data);
            throw new Error('Failed to recognizePed: ' + error.response.data.message);
        } else if (error.request) {
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            console.error('General error:', error.message);
            throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};


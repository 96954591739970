//////// SALE - refactored in 28 th march 2025

import http from '../services/httpService';
// import { debug, error } from '../services/logger';

export const fetchCatSalesAll = async () => {
    try {
        // console.log('API.js | fetchCatSalesAll evoked ')
        const url = `/sales/all-cats`;
        // console.log('API.js | fetchCatSalesAll | URL to be fetched: ', url);
        const res = await http.get(url); //payload
        // console.log('API.js | fetchCatSalesAll | res.dat = ', res.data)
        const { success, data, message } = res.data;
        if (!success || !data || (Array.isArray(data) && data.length === 0)) {
            return {
                success: false,
                data: null,
                message: message || 'No sale information found'
            };
        }

        return {
            success: true,
            data,
            message: message || 'Sales fetched successfully'
        };
    } catch (error) {
        return {
            success: false,
            data: null,
            message: error?.response?.data?.message || 'Request failed'
        };
    }
};

export const fetchSellingInformationById = async (catId) => {
    try {
        const res = await http.get(`/sales/${catId}`);
        // console.log('Api.js | fetchSellingInformationById evoked  res = ', res)
        const { success, data, message } = res?.data || {};
        if (!success || !data || (Array.isArray(data) && data.length === 0)) {
            console.warn(`No sale info found for cat ID ${catId}`);
            return {
                success: false,
                data: null,
                message: message || `No sale information found for cat ID ${catId}`
            };
        }

        return {
            success: true,
            data,
            message: message || 'Sale information fetched successfully'
        };
    } catch (error) {
        console.error('Error fetching sale information:', error);
        return {
            success: false,
            data: null,
            message: error?.response?.data?.message || error.message || 'Unknown error occurred'
        };
    }
};

// if no such filteres cat we need to do a proper screen
export const fetchCatSaleFilter = async (breed) => {
    try {
        // console.log('api.js > fetchCatSalesFilter evoked breed =', breed)
        const res = await http.get(`/sales/cats-sales?${breed}`);
        const { success, data, message } = res?.data || {};

        // console.log('data from fetchCatSalesAll = ', data)
        if (!success || !data || (Array.isArray(data) && data.length === 0)) {
            console.warn('No cats found for the given filter');
            return {
                success: false,
                data: null,
                message: message || 'No cats found for the given filter'
            };
        }

        return {
            success: true,
            data,
            message: message || 'Filtered cats fetched successfully'
        };
    } catch (error) {
        console.error('fetchCatSaleFilter error:', error);
        return {
            success: false,
            data: null,
            message: error?.response?.data?.message || error.message || 'Unknown error occurred'
        };
    }
}

export const ApiUpdateIsActive = async (obj) => {
    try {
        // console.log('API.js | ApiUpdateIsActive | obj =', obj)
        // Destructure catId and isActive from the obj
        const { cat_id, is_active } = obj;
        const { data } = await http.put(`/sales/${cat_id}/is-active-update`, { is_active });// /:id/is-active-update
        // console.log('data from fetchCatSalesAll = ', data)
        return data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.log('No cats found for the given filter');
            return null; // or an empty array []
        } else {
            console.error('An error occurred:', error);
            throw error; // rethrow the error if it's not a 404
        }
    }
};


export const sendToSell = async (values) => {

    try {
        const response = await http.post(`/sales/${values.cat_id}`, values);
        // console.log( 'api.js response = ', response)
        return response.data;
    } catch (error) {
        // Log the error for debugging
        console.error('Error in sendOrder:', error);

        // You can customize this part to handle different types of errors
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            throw new Error('Failed to send to sell: ' + error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('General error:', error.message);
            throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};
// import { Link } from "react-router-dom"
import { useContext } from "react";
import { DropdownMenu } from "../../../components/ui/DropdownMenu";
import ava from "../../../img/user-ava.jpeg";
import './UserTab.scss';
import { LanguageContext } from "../../../context/langContext";

// import { useState } from "react";

// const DropdownMenu = (props2) => {
//   const [isOpen, setIsOpen] = useState(false);
//   // console.log('props DropDownMeny = ', props)
//   const toggleDropdown = () => setIsOpen(!isOpen);

//   return (
//     <div className="dropdown">
//       <button onClick={toggleDropdown} className="dropbtn">⋮</button>
//       {isOpen && (
//         <div className="dropdown-content">
//           <Link to={`/users/${props2.currentUserId}/addphoto`}>Add a photo</Link>
//           <Link to={`/edit`}>Update</Link>
//           <Link to={`/settings`}>Settings</Link>
//         </div>
//       )}
//     </div>
//   );
// }


const UserTab = (props) => {
  const { translations, language } = useContext(LanguageContext)
  const { last_name, middle_name, first_name, birthday, about, user_ava_url, site_url, country_en, country_ru } = props.user;
  // console.log("props = ", props)
  return (
    <div className="cat-owner-tab">
      <div className="profile__header">
        <b>{first_name} {middle_name && middle_name} {last_name} </b>
        <DropdownMenu 
        currentUserId={props.currentUserId} 
        which={'user'}
        linkAddPhoto='Add a photo'
        linkUpdate="Update"
        />
      </div>
      <div className="profile__up">
        <div className="img-container">
          {props.user.user_ava_url ? (<img src={user_ava_url} alt="userAva"></img>) : (<img src={ava} alt="userAva"></img>)}
        </div>
        <div className="profile__info">
          <p className="profile-text-gray"><span>{translations.my.total_cats} </span><strong>{props.numberOfCats}</strong></p>
          <p className="profile-text-gray"><span>{translations.my.birthday} </span><strong>{birthday}</strong></p>
          <p className="profile-text-gray"><span>{translations.my.site} </span><strong>{site_url}</strong></p>
          <p className="profile-text-gray"><span>{translations.my.country} </span><strong>{language === 'ru' ? country_ru : country_en}</strong></p>
        </div>
      </div>
      <div className="profile__text">
        <small>{about}</small>
        {/* {process.env.PUBLIC_URL + '/img/logo.png'} */}
      </div>
      {/* <div className="button-container">
        <Link to={`/users/${props.currentUserId}/addphoto`}><button className="profile-button">Add a photo</button></Link>
        <Link to={`/edit`}><button className="profile-button">Update</button></Link>
        <Link to={`/settings`}><button className="profile-button">Settings</button></Link>
      </div> */}
    </div>
  )
}

export default UserTab
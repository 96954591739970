// import PhotoTab from "./PedigreeTab/PhotoTab";
// import { useParams } from "react-router-dom";
import { useTranslations } from "../../../hooks/useTranslations";
import TabView from "../../../components/ui/TabView/TabView";
import PedigreeTab from "../CatProfileTabs/PedigreeTab";
import DocumentsTab from "../CatProfileTabs/DocumentsTab";
import ParentsTab from "../CatProfileTabs/ParentsTab";
import SellingTab from "../CatProfileTabs/SellingTab/SellingTab";
// import OtherTab from "../OtherTab/OtherTab";
import './catProfileLow.scss'
import { debug } from "../../../services/logger";
// import { useContext } from "react";
// import { CatContext } from "../../context/CatContext";

const CatProfileLow = ({ catProfileValue }) => {
    debug("CatProfileLow | catProfileValue ", catProfileValue)
    // const catId = useParams().id;
    const { translations } = useTranslations();
    // const { data } = useContext(CatContext);
    // console.log()
    const editMode = catProfileValue?.editMode;
    // editMode can be { 'edit_public_locked', 'edit_reg_locked', 'edit_parent_partial_locked', 'edit_unlocked' } - from backend CatController
    // console.log('CatProfileUp | catProfileValue.editMode = ', editMode)

    if (catProfileValue?.error403 || editMode === 'edit_public_locked') {
        return (
            <TabView title={""} tabs={[
                { name: translations.parents, content: <ParentsTab catProfileValue={catProfileValue} /> },
                { name: translations.pedigree, content: <PedigreeTab catProfileValue={catProfileValue} /> },
                // { name: translations.docs, content: <DocumentsTab catProfileValue={catProfileValue} /> },
                // { name: "Photos", content: <PhotoTab text={'Some photo should be here'} /> },
                // { name: translations.cat_profile.other, content: <OtherTab catProfileValue={catProfileValue} /> },
                // { name: translations.cat_profile.selling, content: <SellingTab /> },
            ]} />
        )
    } else {
        return (
            <TabView title={""} tabs={[
                { name: translations.parents, content: <ParentsTab catProfileValue={catProfileValue}/> },
                { name: translations.pedigree, content: <PedigreeTab catProfileValue={catProfileValue} /> },
                { name: translations.docs, content: <DocumentsTab catProfileValue={catProfileValue} /> },
                // { name: "Photos", content: <PhotoTab text={'Some photo should be here'} /> },
                // { name: translations.cat_profile.other, content: <OtherTab catProfileValue={catProfileValue} /> },
                { name: translations.cat_profile.selling, content: <SellingTab /> },
            ]} />
        )
    }
}
export default CatProfileLow;


import http from '../services/httpService';
// import { debug, error } from '../services/logger';

export const sendOrder = async (orderDetails) => {
    // console.log('sendOrder evoked orderDetails =', orderDetails)
    try {
        const { data } = await http.post('/order', orderDetails);
        // console.log('API.js > sendOrder > data.data', data.data)

        if (!data.success || !data || (Array.isArray(data) && data.length === 0)) {
            console.warn('No cats found for the given filter');
            return {
                success: false,
                data: null,
                message: data.message || 'No cats found for the given filter'
            };
        }

        return {
            success: true,
            data,
            message: data.message || 'Sale information fetched successfully'
        };

    } catch (error) {
        // Log the error for debugging
        console.error('Error in sendOrder:', error);

        // You can customize this part to handle different types of errors
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            throw new Error('Failed to send order: ' + error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('General error:', error.message);
            throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};

export const getOrders = async () => {
    // console.log('sendOrder evoked orderDetails =', orderDetails)
    try {
        const response = await http.get('/order');
        // console.log('API.js > getOrders > response.data = ', response.data)
        return response.data;
    } catch (error) {
        // Log the error for debugging
        console.error('Error in sendOrder:', error);

        // You can customize this part to handle different types of errors
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            throw new Error('Failed to send order: ' + error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('General error:', error.message);
        throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};

export const getBuyOrders = async () => {
    // console.log('sendOrder evoked orderDetails =', orderDetails)
    try {
        const response = await http.get('/order/buy');
        // console.log('api.js response = ', response)
        if (response.data.success) { return response.data.data; }

    } catch (error) {
        // Log the error for debugging
        console.error('Error in sendOrder:', error);

        // You can customize this part to handle different types of errors
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            throw new Error('Failed to send order: ' + error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request error:', error.request);
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('General error:', error.message);
            throw new Error('Error in setting up the request: ' + error.message);
        }
    }
};
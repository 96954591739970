import http from '../services/httpService';


////// PARENTS
export const fetchParents = async (catId) => {
    const { data } = await http.get(`/parents/${catId}`);
    // console.log('api.js > fetchParents > data = ', data) // expected {id: 2, cat_id: 23, father_id: 84, mother_id: 80}
    return data
};

export const fetchMother = async (motherId) => {
    const { data } = await http.get(`/parents/mother/${motherId}`);
    return data
};

export const fetchFather = async (fatherId) => {
    const { data } = await http.get(`/parents/father/${fatherId}`);
    return data
};

export const deleteMother = (catId, currentUserId) => {
    return http.delete(`/parents/${catId}`, { data: { mother: true, userId: currentUserId } })
};

export const deleteFather = (catId, currentUserId) => {
    return http.delete(`/parents/${catId}`, { data: { father: true, userId: currentUserId } });
};

export const postMother = async (motherId, catId) => {
    const { data } = await http.post(`/parents/`, { motherId, catId });
    return data.data
};

export const postFather = (fatherId, catId) => {
    return http.post(`/parents/`, { fatherId, catId });
};

export const fetchAncestors = async (catId) => {
    const { data } = await http.get(`/parents/${catId}/ancestors`);
    // console.log('api.js fetchAncestors data = ', data)
    return data;
};
import { useEffect, useState } from 'react';
import './PedigreeTab.scss'
import { Link, useLocation } from 'react-router-dom';
// import { useAuth } from '../../../context/authContext';
import usePedigree from '../usePedigree';
import { fetchAncestors } from '../../../api';


const PedigreeTab = ({ catProfileValue }) => {
    // const { cat, metric, pedigree, catId, applicationStatus } = useContext(CatProfileContext);

    // console.log('CatProfileContext = ', CatProfileContext)
    const location = useLocation();
    // const { catId } = useParams();

    const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser
    // console.log('useParents catId = ', catId);
    // const auth = useAuth();
    // const currentUserId = auth.currentUser.id;

    const parents = usePedigree(catId);
    // console.log('parents = ', parents);

    // const [mother, setMother] = useState({});
    // const [father, setFather] = useState({});
    const [ancestors, setAncestors] = useState({});

    useEffect(() => {
        const fethAnces = async () => {
            try {
                const resAncestors = await fetchAncestors(catId);
                // console.log('useEffect resAncestors[0] =', resAncestors[0])
                setAncestors(resAncestors);
            } catch (error) {
                console.log(error);
            }
        }
        fethAnces()
        // setMother(parents.mother);
        // setFather(parents.father);
    }, [parents, catId])

    // console.log('mother.color = ', mother.color);

    return (
        <div className="pedigree-tab">
            {!catProfileValue?.error403 ?
                <p>To fill pedigree, you need to go to parents tab and add mother and father, then go to mother's and father's page and add mother's and father's parents. And so on.</p>
                : <></>
            }
            <div className="grid-container-mother">
                <div className="grid-header1">Parents</div>
                <div className="grid-header2">Grandparents</div>
                <div className="grid-header3">Great Grandparents</div>
                <div className="grid-header4">Great-Great Grandparents</div>
                {/* {(!catProfileValue?.error403 && (ancestors?.mother_id || !ancestors?.mother_id)) ? (
                    <Link className="grid-item grid-mother female nolink" to={ancestors?.mother_id
                        ? `/cats/${ancestors.mother_id}`
                        : `/cats/${catId}/addparent?p=female`}
                    >
                        <div className='grid-parent-toptext'>
                            <p>Mother:</p>
                            <p>id: {ancestors?.mother_id}</p>
                        </div>
                        <b>{ancestors?.mother_prefix} {ancestors?.mother_name} {ancestors?.mother_suffix}</b>
                        <div className='flex'>
                            <p className='pedigree-tab__birthdate'>{ancestors?.mother_birthdate}</p>
                            <p className='pedigree-tab__ems'>
                                {ancestors?.mother_main_ems}
                                {ancestors?.mother_gold_silver_ems}
                                {ancestors?.mother_white_ems}
                                {ancestors?.mother_tabby_ems}
                                {ancestors?.mother_point_ems}
                                {ancestors?.mother_eye_ems}
                            </p>
                            <p className='pedigree-tab__ems'>{ancestors?.mother_breed_ems}</p>
                        </div>
                    </Link>
                ) : null} */}
                <div className="grid-item grid-mother female">
                    {ancestors?.mother_id || (!ancestors?.mother_id && !catProfileValue?.error403) ? (
                        <Link className="nolink" to={ancestors?.mother_id
                            ? `/cats/${ancestors.mother_id}`
                            : `/cats/${catId}/addparent?p=female`}
                        >
                            <div className='grid-parent-toptext'>
                                <p>Mother:</p>
                                <p>id: {ancestors?.mother_id}</p>
                            </div>
                            <b>{ancestors?.mother_prefix} {ancestors?.mother_name} {ancestors?.mother_suffix}</b>
                            <div className='flex'>
                                <p className='pedigree-tab__birthdate'>{ancestors?.mother_birthdate}</p>
                                <p className='pedigree-tab__ems'>
                                    {ancestors?.mother_main_ems}
                                    {ancestors?.mother_gold_silver_ems}
                                    {ancestors?.mother_white_ems}
                                    {ancestors?.mother_tabby_ems}
                                    {ancestors?.mother_point_ems}
                                    {ancestors?.mother_eye_ems}
                                </p>
                                <p className='pedigree-tab__ems'>{ancestors?.mother_breed_ems}</p>
                            </div>
                        </Link>
                    ) : (
                        <>
                            <div className='grid-parent-toptext'>
                                <p>Mother:</p>
                                <p>id: {ancestors?.mother_id}</p>
                            </div>
                            <b>{ancestors?.mother_prefix} {ancestors?.mother_name} {ancestors?.mother_suffix}</b>
                            <div className='flex'>
                                <p className='pedigree-tab__birthdate'>{ancestors?.mother_birthdate}</p>
                                <p className='pedigree-tab__ems'>
                                    {ancestors?.mother_main_ems}
                                    {ancestors?.mother_gold_silver_ems}
                                    {ancestors?.mother_white_ems}
                                    {ancestors?.mother_tabby_ems}
                                    {ancestors?.mother_point_ems}
                                    {ancestors?.mother_eye_ems}
                                </p>
                                <p className='pedigree-tab__ems'>{ancestors?.mother_breed_ems}</p>
                            </div>
                        </>
                    )}
                </div>

                <Link className="grid-item grid-mother-grandmother female nolink"
                    to={
                        ancestors?.maternal_grandmother_id
                            ? `/cats/${ancestors?.maternal_grandmother_id}`
                            : `/add`
                    }>
                    <div className="grid-parent-toptext">
                        <p>Grandmother:</p>
                        <p>id: {ancestors?.maternal_grandmother_id}</p>
                    </div>
                    <b>{ancestors?.maternal_grandmother_cat_name_cattery_prefix} {ancestors?.maternal_grandmother_cat_name} {ancestors?.maternal_grandmother_cat_name_suffix}
                    </b>
                    <div className='flex'>
                        <p className='pedigree-tab__birthdate'>{ancestors?.maternal_grandmother_birthdate}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_grandmother_main_ems}
                            {ancestors?.maternal_grandmother_gold_silver_ems}
                            {ancestors?.maternal_grandmother_white_ems}
                            {ancestors?.maternal_grandmother_tabby_ems}
                            {ancestors?.maternal_grandmother_point_ems}
                            {ancestors?.maternal_grandmother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_grandmother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandfather male nolink"
                    to={
                        ancestors?.maternal_grandfather_id
                            ? `/cats/${ancestors?.maternal_grandfather_id}`
                            : `/add`
                    }>
                    <div className="grid-parent-toptext">
                        <p>Grandfather:</p>
                        <p>id: {ancestors?.maternal_grandfather_id}</p>
                    </div>
                    <b>
                        {ancestors?.maternal_grandfather_cat_name_cattery_prefix} {ancestors?.maternal_grandfather_cat_name} {ancestors?.maternal_grandfather_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__birthdate'>{ancestors?.maternal_grandfather_birthdate}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_grandfather_main_ems}
                            {ancestors?.maternal_grandfather_gold_silver_ems}
                            {ancestors?.maternal_grandfather_white_ems}
                            {ancestors?.maternal_grandfather_tabby_ems}
                            {ancestors?.maternal_grandfather_point_ems}
                            {ancestors?.maternal_grandfather_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_grandfather_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandmother-great-grandmother female nolink"
                    to={
                        ancestors?.maternal_gm_ggm_id
                            ? `/cats/${ancestors?.maternal_gm_ggm_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandmother:</p>
                        <p>id: {ancestors?.maternal_gm_ggm_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gm_ggm_cat_name_cattery_prefix} {ancestors?.maternal_gm_ggm_cat_name} {ancestors?.maternal_gm_ggm_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggm_main_ems}
                            {ancestors?.maternal_gm_ggm_gold_silver_ems}
                            {ancestors?.maternal_gm_ggm_white_ems}
                            {ancestors?.maternal_gm_ggm_tabby_ems}
                            {ancestors?.maternal_gm_ggm_point_ems}
                            {ancestors?.maternal_gm_ggm_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggm_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandmother-great-grandfather male nolink"
                    to={
                        ancestors?.maternal_gm_ggf_id
                            ? `/cats/${ancestors?.maternal_gm_ggf_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandfather:</p>
                        <p>id: {ancestors?.maternal_gm_ggf_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gm_ggf_cat_name_cattery_prefix} {ancestors?.maternal_gm_ggf_cat_name} {ancestors?.maternal_gm_ggf_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggf_main_ems}
                            {ancestors?.maternal_gm_ggf_gold_silver_ems}
                            {ancestors?.maternal_gm_ggf_white_ems}
                            {ancestors?.maternal_gm_ggf_tabby_ems}
                            {ancestors?.maternal_gm_ggf_point_ems}
                            {ancestors?.maternal_gm_ggf_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggf_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandfather-great-grandmother female nolink"
                    to={
                        ancestors?.maternal_gf_ggm_id
                            ? `/cats/${ancestors?.maternal_gf_ggm_id}`
                            : `/add`
                    }
                >
                    <div className='grid-parent-toptext'>
                        <p>Great Grandmother:</p>
                        <p>id: {ancestors?.maternal_gf_ggm_id}</p>
                    </div>
                    <b>{ancestors?.maternal_gf_ggm_cat_name_cattery_prefix} {ancestors?.maternal_gf_ggm_cat_name} {ancestors?.maternal_gf_ggm_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggm_main_ems}
                            {ancestors?.maternal_gf_ggm_gold_silver_ems}
                            {ancestors?.maternal_gf_ggm_white_ems}
                            {ancestors?.maternal_gf_ggm_tabby_ems}
                            {ancestors?.maternal_gf_ggm_point_ems}
                            {ancestors?.maternal_gf_ggm_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggm_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandfather-great-grandfather male nolink"
                    to={
                        ancestors?.maternal_gf_ggf_id
                            ? `/cats/${ancestors?.maternal_gf_ggf_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandfather:</p>
                        <p>id: {ancestors?.maternal_gf_ggf_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gf_ggf_cat_name_cattery_prefix} {ancestors?.maternal_gf_ggf_cat_name} {ancestors?.maternal_gf_ggf_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggf_main_ems}
                            {ancestors?.maternal_gf_ggf_gold_silver_ems}
                            {ancestors?.maternal_gf_ggf_white_ems}
                            {ancestors?.maternal_gf_ggf_tabby_ems}
                            {ancestors?.maternal_gf_ggf_point_ems}
                            {ancestors?.maternal_gf_ggf_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggf_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandmother-great-grandmother-mother female nolink"
                    to={
                        ancestors?.maternal_gm_ggm_grandmother_id
                            ? `/cats/${ancestors.maternal_gm_ggm_grandmother_id}`
                            : '/add'
                    }
                >
                    <div className='grid-parent-toptext'>
                        <p>Great-Great Grandmother:</p>
                        <p>id: {ancestors?.maternal_gm_ggm_grandmother_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gm_ggm_grandmother_cat_name_cattery_prefix} {ancestors?.maternal_gm_ggm_grandmother_cat_name} {ancestors?.maternal_gm_ggm_grandmother_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggm_grandmother_main_ems}
                            {ancestors?.maternal_gm_ggm_grandmother_gold_silver_ems}
                            {ancestors?.maternal_gm_ggm_grandmother_white_ems}
                            {ancestors?.maternal_gm_ggm_grandmother_tabby_ems}
                            {ancestors?.maternal_gm_ggm_grandmother_point_ems}
                            {ancestors?.maternal_gm_ggm_grandmother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggm_grandmother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandmother-great-grandmother-father male nolink"
                    to={
                        ancestors?.maternal_gm_ggm_grandfather_id
                            ? `/cats/${ancestors?.maternal_gm_ggm_grandfather_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great-Great Grandfather:</p>
                        <p>id: {ancestors?.maternal_gm_ggm_grandfather_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gm_ggm_grandfather_cat_name_cattery_prefix} {ancestors?.maternal_gm_ggm_grandfather_cat_name} {ancestors?.maternal_gm_ggm_grandfather_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggm_grandfather_main_ems}
                            {ancestors?.maternal_gm_ggm_grandfather_gold_silver_ems}
                            {ancestors?.maternal_gm_ggm_grandfather_white_ems}
                            {ancestors?.maternal_gm_ggm_grandfather_tabby_ems}
                            {ancestors?.maternal_gm_ggm_grandfather_point_ems}
                            {ancestors?.maternal_gm_ggm_grandfather_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggm_grandfather_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandmother-great-grandfather-mother female nolink"
                    to={
                        ancestors?.maternal_gm_ggf_grandmother_id
                            ? `/cats/${ancestors?.maternal_gm_ggf_grandmother_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great-Great Grandmother:</p>
                        <p>id: {ancestors?.maternal_gm_ggf_grandmother_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gm_ggf_grandmother_cat_name_cattery_prefix} {ancestors?.maternal_gm_ggf_grandmother_cat_name} {ancestors?.maternal_gm_ggf_grandmother_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggf_grandmother_main_ems}
                            {ancestors?.maternal_gm_ggf_grandmother_gold_silver_ems}
                            {ancestors?.maternal_gm_ggf_grandmother_white_ems}
                            {ancestors?.maternal_gm_ggf_grandmother_tabby_ems}
                            {ancestors?.maternal_gm_ggf_grandmother_point_ems}
                            {ancestors?.maternal_gm_ggf_grandmother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggf_grandmother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandmother-great-grandfather-father male nolink"
                    to={
                        ancestors?.maternal_gm_ggf_grandfather_id
                            ? `/cats/${ancestors?.maternal_gm_ggf_grandfather_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great-Great Grandfather:</p>
                        <p>id: {ancestors?.maternal_gm_ggf_grandfather_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gm_ggf_grandfather_cat_name_cattery_prefix} {ancestors?.maternal_gm_ggf_grandfather_cat_name} {ancestors?.maternal_gm_ggf_grandfather_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggf_grandfather_main_ems}
                            {ancestors?.maternal_gm_ggf_grandfather_gold_silver_ems}
                            {ancestors?.maternal_gm_ggf_grandfather_white_ems}
                            {ancestors?.maternal_gm_ggf_grandfather_tabby_ems}
                            {ancestors?.maternal_gm_ggf_grandfather_point_ems}
                            {ancestors?.maternal_gm_ggf_grandfather_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gm_ggf_grandfather_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandfather-great-grandmother-mother female nolink"
                    to={
                        ancestors?.maternal_gf_ggm_grandmother_id
                            ? `/cats/${ancestors?.maternal_gf_ggm_grandmother_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great-Great Grandmother:</p>
                        <p>id: {ancestors?.maternal_gf_ggm_grandmother_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gf_ggm_grandmother_cat_name_cattery_prefix} {ancestors?.maternal_gf_ggm_grandmother_cat_name} {ancestors?.maternal_gf_ggm_grandmother_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggm_grandmother_main_ems}
                            {ancestors?.maternal_gf_ggm_grandmother_gold_silver_ems}
                            {ancestors?.maternal_gf_ggm_grandmother_white_ems}
                            {ancestors?.maternal_gf_ggm_grandmother_tabby_ems}
                            {ancestors?.maternal_gf_ggm_grandmother_point_ems}
                            {ancestors?.maternal_gf_ggm_grandmother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggm_grandmother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandfather-great-grandmother-father male nolink"
                    to={
                        ancestors?.maternal_gf_ggm_grandfather_id
                            ? `/cats/${ancestors?.maternal_gf_ggm_grandmfather_id}`
                            : `/add`

                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great-Great Grandfather:</p>
                        <p>id: {ancestors?.maternal_gf_ggm_grandfather_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gf_ggm_grandfather_cat_name_cattery_prefix} {ancestors?.maternal_gf_ggm_grandfather_cat_name} {ancestors?.maternal_gf_ggm_grandfather_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggm_grandfather_main_ems}
                            {ancestors?.maternal_gf_ggm_grandfather_gold_silver_ems}
                            {ancestors?.maternal_gf_ggm_grandfather_white_ems}
                            {ancestors?.maternal_gf_ggm_grandfather_tabby_ems}
                            {ancestors?.maternal_gf_ggm_grandfather_point_ems}
                            {ancestors?.maternal_gf_ggm_grandfather_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggm_grandfather_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandfather-great-grandfather-mother female nolink"
                    to={
                        ancestors?.maternal_gf_ggf_grandmother_id
                            ? `/cats/${ancestors?.maternal_gf_ggf_grandmother_id}`
                            : `/add`

                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great-Great Grandmother:</p>
                        <p>id: {ancestors?.maternal_gf_ggf_grandmother_id}</p>
                    </div>
                    <b>{ancestors?.maternal_gf_ggf_grandmother_cat_name_cattery_prefix} {ancestors?.maternal_gf_ggf_grandmother_cat_name} {ancestors?.maternal_gf_ggf_grandmother_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggf_grandmother_main_ems}
                            {ancestors?.maternal_gf_ggf_grandmother_gold_silver_ems}
                            {ancestors?.maternal_gf_ggf_grandmother_white_ems}
                            {ancestors?.maternal_gf_ggf_grandmother_tabby_ems}
                            {ancestors?.maternal_gf_ggf_grandmother_point_ems}
                            {ancestors?.maternal_gf_ggf_grandmother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggf_grandmother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-mother-grandfather-great-grandfather-father male nolink"
                    to={
                        ancestors?.maternal_gf_ggf_grandfather_id
                            ? `/cats/${ancestors?.maternal_gf_ggf_grandfather_id}`
                            : `/add`

                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great-Great Grandfather:</p>
                        <p>id: {ancestors?.maternal_gf_ggf_grandfather_id}</p>
                    </div>

                    <b>{ancestors?.maternal_gf_ggf_grandfather_cat_name_cattery_prefix} {ancestors?.maternal_gf_ggf_grandfather_cat_name} {ancestors?.maternal_gf_ggf_grandfather_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggf_grandfather_main_ems}
                            {ancestors?.maternal_gf_ggf_grandfather_gold_silver_ems}
                            {ancestors?.maternal_gf_ggf_grandfather_white_ems}
                            {ancestors?.maternal_gf_ggf_grandfather_tabby_ems}
                            {ancestors?.maternal_gf_ggf_grandfather_point_ems}
                            {ancestors?.maternal_gf_ggf_grandfather_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.maternal_gf_ggf_grandfather_breed_ems}</p>
                    </div>
                </Link>
            </div>
            {/* maternal_gm_ggm */}
            {/* maternal_gm_ggf */}
            {/* Father */}
            <div className="grid-container-father">
                <div className="grid-item grid-father male">
                    {ancestors?.father_id || (!ancestors?.father_id && !catProfileValue?.error403) ? (
                        <Link className="nolink" to={ancestors?.father_id
                            ? `/cats/${ancestors.father_id}`
                            : `/cats/${catId}/addparent?p=male`}
                        >
                            <div className="grid-parent-toptext">
                                <p>Father:</p>
                                <p>id {ancestors?.father_id}</p>
                            </div>
                            <b>{ancestors?.father_prefix} {ancestors?.father_name} {ancestors?.father_suffix}</b>
                            <div className='flex'>
                                <p className='pedigree-tab__birthdate'>{ancestors?.father_birthdate}</p>
                                <p className='pedigree-tab__ems'>
                                    {ancestors?.father_color_main_ems}
                                    {ancestors?.father_gold_silver_ems}
                                    {ancestors?.father_white_ems}
                                    {ancestors?.father_tabby_ems}
                                    {ancestors?.father_point_ems}
                                    {ancestors?.father_eye_ems}
                                </p>
                                <p className='pedigree-tab__ems'>{ancestors?.father_breed_ems}</p>
                            </div>
                        </Link>
                    ) : (
                        <>
                            <div className="grid-parent-toptext">
                                <p>Father:</p>
                                <p>id {ancestors?.father_id}</p>
                            </div>
                            <b>{ancestors?.father_prefix} {ancestors?.father_name} {ancestors?.father_suffix}</b>
                            <div className='flex'>
                                <p className='pedigree-tab__birthdate'>{ancestors?.father_birthdate}</p>
                                <p className='pedigree-tab__ems'>
                                    {ancestors?.father_color_main_ems}
                                    {ancestors?.father_gold_silver_ems}
                                    {ancestors?.father_white_ems}
                                    {ancestors?.father_tabby_ems}
                                    {ancestors?.father_point_ems}
                                    {ancestors?.father_eye_ems}
                                </p>
                                <p className='pedigree-tab__ems'>{ancestors?.father_breed_ems}</p>
                            </div>
                        </>
                    )}
                </div>

                <Link className="grid-item grid-father-grandmother female nolink" to={
                    ancestors?.paternal_grandmother_id
                        ? `/cats/${ancestors?.paternal_grandmother_id}`
                        : `/add`
                }>
                    <div className="grid-parent-toptext">
                        <p>Grandmother:</p>
                        <p>id {ancestors?.paternal_grandmother_id}</p>
                    </div>
                    <b>{ancestors?.paternal_grandmother_cat_name_cattery_prefix} {ancestors?.paternal_grandmother_cat_name} {ancestors?.paternal_grandmother_cat_name_suffix}</b>
                    <div className="flex">
                        <p className='pedigree-tab__birthdate'>{ancestors?.paternal_grandmother_birthdate}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_grandmother_color_main_ems}
                            {ancestors?.paternal_grandmother_gold_silver_ems}
                            {ancestors?.paternal_grandmother_white_ems}
                            {ancestors?.paternal_grandmother_tabby_ems}
                            {ancestors?.paternal_grandmother_point_ems}
                            {ancestors?.paternal_grandmother_eye_color_ems}
                        </p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_grandmother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandfather male nolink" to={
                    ancestors?.paternal_grandfather_id
                        ? `/cats/${ancestors?.paternal_grandfather_id}`
                        : `/add`
                }>
                    <div className="grid-parent-toptext">
                        <p>Grandfather:</p>
                        <p>id {ancestors?.paternal_grandfather_id}</p>
                    </div>

                    <b>{ancestors?.paternal_grandfather_cat_name_cattery_prefix} {ancestors?.paternal_grandfather_cat_name} {ancestors?.paternal_grandfather_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__birthdate'>{ancestors?.paternal_grandfather_birthdate}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_grandfather_color_main_ems}
                            {ancestors?.paternal_grandfather_gold_silver_ems}
                            {ancestors?.paternal_grandfather_white_ems}
                            {ancestors?.paternal_grandfather_tabby_ems}
                            {ancestors?.paternal_grandfather_point_ems}
                            {ancestors?.paternal_grandfather_eye_color_ems}
                        </p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_grandfather_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandmother-great-grandmother female nolink"
                    to={
                        ancestors?.paternal_gm_ggm_id
                            ? `/cats/${ancestors?.paternal_gm_ggm_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandmother:</p>
                        <p>id: {ancestors?.paternal_gm_ggm_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gm_ggm_cat_name_cattery_prefix} {ancestors?.paternal_gm_ggm_cat_name} {ancestors?.paternal_gm_ggm_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggm_color_main_ems}
                            {ancestors?.paternal_gm_ggm_gold_silver_ems}
                            {ancestors?.paternal_gm_ggm_white_ems}
                            {ancestors?.paternal_gm_ggm_tabby_ems}
                            {ancestors?.paternal_gm_ggm_point_ems}
                            {ancestors?.paternal_ggm_ggm_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggm_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandmother-great-grandfather male nolink"
                    to={
                        ancestors?.paternal_gm_ggf_id
                            ? `/cats/${ancestors?.paternal_gm_ggf_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandfather:</p>
                        <p>id: {ancestors?.paternal_gm_ggf_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gm_ggf_cat_name_cattery_prefix} {ancestors?.paternal_gm_ggf_cat_name} {ancestors?.paternal_gm_ggf_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggf_color_main_ems}
                            {ancestors?.paternal_gm_ggf_gold_silver_ems}
                            {ancestors?.paternal_gm_ggf_white_ems}
                            {ancestors?.paternal_gm_ggf_tabby_ems}
                            {ancestors?.paternal_gm_ggf_point_ems}
                            {ancestors?.paternal_gm_ggf_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggf_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandfather-great-grandmother female nolink"
                    to={
                        ancestors?.paternal_gf_ggm_id
                            ? `/cats/${ancestors?.paternal_gf_ggm_id}`
                            : `/add`
                    } >
                    <div className='grid-parent-toptext'>
                        <p>Great Grandmother:</p>
                        <p>id: {ancestors?.paternal_gf_ggm_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gf_ggm_cat_name_cattery_prefix} {ancestors?.paternal_gf_ggm_cat_name} {ancestors?.paternal_gf_ggm_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggm_color_main_ems}
                            {ancestors?.paternal_gf_ggm_gold_silver_ems}
                            {ancestors?.paternal_gf_ggm_white_ems}
                            {ancestors?.paternal_gf_ggm_tabby_ems}
                            {ancestors?.paternal_gf_ggm_point_ems}
                            {ancestors?.paternal_gf_ggm_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggm_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandfather-great-grandfather male nolink"
                    to={
                        ancestors?.paternal_gf_ggf_id
                            ? `/cats/${ancestors?.paternal_gf_ggf_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandfather:</p>
                        <p>id: {ancestors?.paternal_gf_ggf_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gf_ggf_cat_name_cattery_prefix} {ancestors?.paternal_gf_ggf_cat_name} {ancestors?.paternal_gf_ggf_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggf_color_main_ems}
                            {ancestors?.paternal_gf_ggf_gold_silver_ems}
                            {ancestors?.paternal_gf_ggf_white_ems}
                            {ancestors?.paternal_gf_ggf_tabby_ems}
                            {ancestors?.paternal_gf_ggf_point_ems}
                            {ancestors?.paternal_gf_ggf_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggf_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandmother-great-grandmother-mother female nolink"
                    to={
                        ancestors?.paternal_gm_ggm_mother_id
                            ? `/cats/${ancestors?.paternal_gm_ggm_mother_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandmother:</p>
                        <p>id: {ancestors?.paternal_gm_ggm_mother_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gm_ggm_mother_cat_name_cattery_prefix} {ancestors?.paternal_gm_ggm_mother_cat_name} {ancestors?.paternal_gm_ggm_mother_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggm_mother_color_main_ems}
                            {ancestors?.paternal_gm_ggm_mother_gold_silver_ems}
                            {ancestors?.paternal_gm_ggm_mother_white_ems}
                            {ancestors?.paternal_gm_ggm_mother_tabby_ems}
                            {ancestors?.paternal_gm_ggm_mother_point_ems}
                            {ancestors?.paternal_ggm_ggm_mother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggm_mother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandmother-great-grandmother-father male nolink"
                    to={
                        ancestors?.paternal_gm_ggm_father_id
                            ? `/cats/${ancestors?.paternal_gm_ggm_father_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandfather:</p>
                        <p>id: {ancestors?.paternal_gm_ggm_father_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gm_ggm_father_cat_name_cattery_prefix} {ancestors?.paternal_gm_ggm_father_cat_name} {ancestors?.paternal_gm_ggm_father_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggm_father_color_main_ems}
                            {ancestors?.paternal_gm_ggm_father_gold_silver_ems}
                            {ancestors?.paternal_gm_ggm_father_white_ems}
                            {ancestors?.paternal_gm_ggm_father_tabby_ems}
                            {ancestors?.paternal_gm_ggm_father_point_ems}
                            {ancestors?.paternal_gm_ggm_father_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggm_father_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandmother-great-grandfather-mother female nolink"
                    to={
                        ancestors?.paternal_gm_ggf_mother_id
                            ? `/cats/${ancestors?.paternal_gm_ggf_mother_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandmother:</p>
                        <p>id: {ancestors?.paternal_gm_ggf_mother_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gm_ggf_mother_cat_name_cattery_prefix} {ancestors?.paternal_gm_ggf_mother_cat_name} {ancestors?.paternal_gm_ggf_mother_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggf_mother_color_main_ems}
                            {ancestors?.paternal_gm_ggf_mother_gold_silver_ems}
                            {ancestors?.paternal_gm_ggf_mother_white_ems}
                            {ancestors?.paternal_gm_ggf_mother_tabby_ems}
                            {ancestors?.paternal_gm_ggf_mother_point_ems}
                            {ancestors?.paternal_gm_ggf_mother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggf_mother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandmother-great-grandfather-father male nolink"
                    to={
                        ancestors?.paternal_gm_ggf_father_id
                            ? `/cats/${ancestors?.paternal_gm_ggf_father_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandfather:</p>
                        <p>id: {ancestors?.paternal_gm_ggf_father_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gm_ggf_father_cat_name_cattery_prefix} {ancestors?.paternal_gm_ggf_father_cat_name} {ancestors?.paternal_gm_ggf_father_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggf_father_color_main_ems}
                            {ancestors?.paternal_gm_ggf_father_gold_silver_ems}
                            {ancestors?.paternal_gm_ggf_father_white_ems}
                            {ancestors?.paternal_gm_ggf_father_tabby_ems}
                            {ancestors?.paternal_gm_ggf_father_point_ems}
                            {ancestors?.paternal_gm_ggf_father_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gm_ggf_father_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandfather-great-grandmother-mother female nolink"
                    to={
                        ancestors?.paternal_gf_ggm_mother_id
                            ? `/cats/${ancestors?.paternal_gf_ggm_mother_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandmother:</p>
                        <p>id: {ancestors?.paternal_gf_ggm_mother_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gf_ggm_mother_cat_name_cattery_prefix} {ancestors?.paternal_gf_ggm_mother_cat_name} {ancestors?.paternal_gf_ggm_mother_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggm_mother_color_main_ems}
                            {ancestors?.paternal_gf_ggm_mother_gold_silver_ems}
                            {ancestors?.paternal_gf_ggm_mother_white_ems}
                            {ancestors?.paternal_gf_ggm_mother_tabby_ems}
                            {ancestors?.paternal_gf_ggm_mother_point_ems}
                            {ancestors?.paternal_gf_ggm_mother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggm_mother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandfather-great-grandmother-father male nolink"
                    to={
                        ancestors?.paternal_gf_ggm_father_id
                            ? `/cats/${ancestors?.paternal_gf_ggm_father_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandfather:</p>
                        <p>id: {ancestors?.paternal_gf_ggm_father_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gf_ggm_father_cat_name_cattery_prefix} {ancestors?.paternal_gf_ggm_father_cat_name} {ancestors?.paternal_gf_ggm_father_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggm_father_color_main_ems}
                            {ancestors?.paternal_gf_ggm_father_gold_silver_ems}
                            {ancestors?.paternal_gf_ggm_father_white_ems}
                            {ancestors?.paternal_gf_ggm_father_tabby_ems}
                            {ancestors?.paternal_gf_ggm_father_point_ems}
                            {ancestors?.paternal_gf_ggm_father_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggm_father_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandfather-great-grandfather-mother female nolink"
                    to={
                        ancestors?.paternal_gf_ggf_mother_id
                            ? `/cats/${ancestors?.paternal_gf_ggf_mother_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandmother:</p>
                        <p>id: {ancestors?.paternal_gf_ggf_mother_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gf_ggf_mother_cat_name_cattery_prefix} {ancestors?.paternal_gf_ggf_mother_cat_name} {ancestors?.paternal_gf_ggf_mother_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggf_mother_color_main_ems}
                            {ancestors?.paternal_gf_ggf_mother_gold_silver_ems}
                            {ancestors?.paternal_gf_ggf_mother_white_ems}
                            {ancestors?.paternal_gf_ggf_mother_tabby_ems}
                            {ancestors?.paternal_gf_ggf_mother_point_ems}
                            {ancestors?.paternal_gf_ggf_mother_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggf_mother_breed_ems}</p>
                    </div>
                </Link>
                <Link className="grid-item grid-father-grandfather-great-grandfather-father male nolink"
                    to={
                        ancestors?.paternal_gf_ggf_father_id
                            ? `/cats/${ancestors?.paternal_gf_ggf_father_id}`
                            : `/add`
                    }>
                    <div className='grid-parent-toptext'>
                        <p>Great Grandfather:</p>
                        <p>id: {ancestors?.paternal_gf_ggf_father_id}</p>
                    </div>
                    <b>{ancestors?.paternal_gf_ggf_father_cat_name_cattery_prefix} {ancestors?.paternal_gf_ggf_father_cat_name} {ancestors?.paternal_gf_ggf_father_cat_name_suffix}</b>
                    <div className='flex'>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggf_father_color_main_ems}
                            {ancestors?.paternal_gf_ggf_father_gold_silver_ems}
                            {ancestors?.paternal_gf_ggf_father_white_ems}
                            {ancestors?.paternal_gf_ggf_father_tabby_ems}
                            {ancestors?.paternal_gf_ggf_father_point_ems}
                            {ancestors?.paternal_gf_ggf_father_eye_color_ems}</p>
                        <p className='pedigree-tab__ems'>{ancestors?.paternal_gf_ggf_father_breed_ems}</p>
                    </div>
                </Link>
            </div>
        </div >
        // const {metric_num, metric_id, issued_by, abb, metric_img_url, pedigree_num, pedigrees_id, pedigree_issied_by, pedigree_abb, pedigree_img_url, catId, registered} = props;
    )
}

export default PedigreeTab

// [
//     {
//       "father_breed_ems": "b",
//       "father_eye_ems": "67",
//       "father_gold_silver_ems": null,
//       "father_id": 77,
//       "father_main_ems": "b",
//       "father_name": "Yanus Snow Bars Tais",
//       "father_point_ems": "33",
//       "father_prefix": "",
//       "father_suffix": null,
//       "father_tabby_ems": "21",
//       "father_white_ems": null
//     },
//     {
//       "maternal_grandfather_cat_name_suffix": null,
//       "maternal_grandfather_name": "qqffff",
//       "maternal_grandfather_name_cattery_prefix": ""
//     },
//     {
//       "maternal_grandmother_cat_name": "Afina 4",
//       "maternal_grandmother_cat_name_cattery_prefix": null,
//       "maternal_grandmother_cat_name_suffix": null
//     },
//     {
//       "mother_breed_ems": "f",
//       "mother_eye_ems": "67",
//       "mother_gold_silver_ems": null,
//       "mother_id": 78,
//       "mother_main_ems": "f",
//       "mother_name": "Cherry Mirt",
//       "mother_point_ems": "33",
//       "mother_prefix": "",
//       "mother_suffix": null,
//       "mother_tabby_ems": null,
//       "mother_white_ems": null
//     },
//     {
//       "paternal_grandfather_cat_name": null,
//       "paternal_grandfather_cat_name_cattery_prefix": null,
//       "paternal_grandfather_cat_name_suffix": null
//     },
//     {
//       "paternal_grandmother_cat_name": null,
//       "paternal_grandmother_cat_name_cattery_prefix": null,
//       "paternal_grandmother_cat_name_suffix": null
//     }
//   ]


import { useEffect, useState } from 'react';
import { fetchBreedById, fetchColor, fetchFather, fetchMother, fetchParents } from '../../api';

//Data we need to get from usePedigree:
// 
const usePedigree = (catId) => {
    const [parents, setParents] = useState({ mother: {}, father: {} });

    useEffect(() => {
        let isMounted = true;

        const fetchParentData = async (parentId, isMother = true) => {
            try {
                const parent = await (isMother ? fetchMother(parentId) : fetchFather(parentId));
                const breed = await fetchBreedById(parent.breed_id);
                const color = await fetchColor(parentId);
                return {
                    ...parent[0],
                    emsBreed: breed?.ems || {},
                    color: color?.data || {}
                };
            } catch (error) {
                console.error(`Error fetching ${isMother ? 'mother' : 'father'} data:`, error);
                return {};
            }
        };

        const fetchCatRelatedData = async () => {
            try {
                const resDataParents = await fetchParents(catId);
                if (resDataParents && resDataParents[0] && isMounted) {
                    const { mother_id, father_id } = resDataParents;
                    const motherData = mother_id ? await fetchParentData(mother_id, true) : {};
                    const fatherData = father_id ? await fetchParentData(father_id, false) : {};
                    setParents({ mother: motherData, father: fatherData });
                }
            } catch (error) {
                console.error('Error fetching parent data:', error);
                if (isMounted) setParents({ mother: {}, father: {} });
            }
        };

        fetchCatRelatedData();

        return () => {
            isMounted = false;
        };
    }, [catId]);

    return parents;
};

export default usePedigree;

import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react';
import { useAuth } from '../context/authContext.js';
import FormInput from '../components/FormInput/FormInput.jsx';
import { changeEmail, fetchUserSettings } from '../api';

import './settings.scss'
import { LanguageContext } from '../context/langContext.js';

const Settings = () => {
    const { translations } = useContext(LanguageContext)

    const auth = useAuth();
    const currentUserId = auth.currentUser.id;
    const navigate = useNavigate();
    // console.log('auth =', auth)
    const [user, setUser] = useState({
        email: "",
    });

    const [writtenEmail, setWrittenEmail] = useState({
        email: "",
        id: auth.currentUser.id
    });

    const [emailChangeMessage, setEmailChangeMessage] = useState("");


    const inputs = [
        {
            id: 1,
            name: "email",
            type: "email",
            placeholder: "Email",
            // errorMessage: "It should be a valid email address!",
            // label: "Email",
            // required: true,
            // className:"joinInput"
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resUser = await fetchUserSettings() //return res.data already
                // console.log('resUser', resUser)
                if (resUser) setUser(resUser.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData(currentUserId)
    }, [currentUserId])

    const onChangeEmail = (e) => {
        setWrittenEmail((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    };

    const onSubmitEmail = async (e) => {
        e.preventDefault();
        try {
            const result = await changeEmail(writtenEmail)
            // console.log('Settings.jsx > onSubmitEmail > result = ', result)
            const { success, message } = result;
            if (success) setEmailChangeMessage(message); // <- Store the backend message here

        } catch (err) {
            console.log(err)
        }
        // navigate('/my')
    }

    return (
        <div className='settings-container'>
            <p>Email: <strong>{user.email}</strong> </p>
            <form onSubmit={onSubmitEmail}>
                <FormInput
                    key={inputs[0].id}
                    name={inputs[0].name}
                    type={inputs[0].type}
                    placeholder={inputs[0].placeholder}
                    value={writtenEmail.email}
                    onChange={onChangeEmail}
                />
                <button className='secondary-button' type='submit'>Change email</button>
            </form>
            {emailChangeMessage && (
                <div>
                    <p className="email-change-message">{emailChangeMessage}</p>
                    <button className='button-light' type="button" onClick={() => navigate('/my')}>
                        {translations.gohome}
                    </button>

                </div>

            )}

        </div>
    )
}

export default Settings

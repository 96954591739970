import { useState } from "react";
import formatDateFromUTC from "../../utils/formatDateFromUTC";
import ReactDOM from 'react-dom';
import { DialogModalAdmin } from "./dialogModelAdmin";
import { useAuth } from "../../context/authContext";
import { deleteApplication, putRegisterPedigree } from "../../api";
import './catNotRegistered.scss'
// import { Navigate } from 'react-router-dom';

export const CatNotRegistered = ({
    application_id,
    cat_id,
    cat_birthdate,
    cat_name,
    cat_name_cattery_prefix,
    num,
    sex,
    breed,
    breeder_first_name,
    breeder_last_name,
    issued_by,
    pedigree_system_abb,
    pedigree_img_url,
    breeder_not_user,
    onDelete }) => {

    const [dialogOpenAdmin, setShowDialogAdmin] = useState(false);
    // const [birthdate, setBirthdate] = useState('')

    const onClickAdmin = () => {
        setShowDialogAdmin(true);
    };

    const onCloseDialogAdmin = () => {
        setShowDialogAdmin(false);
    };

    const auth = useAuth();
    const currentUserId = auth.currentUser.id;

    const fdate = formatDateFromUTC(cat_birthdate);// call our module-function to convert not beautifule date

    // console.log('application_id', application_id)

    const submitPedigree = async () => {
        try {
            // console.log('auth.currentUser.id from CatNotRegistered', auth.currentUser.id)
            // console.log('catId', props.cat_id)
            await putRegisterPedigree(cat_id, currentUserId)
            onDelete(cat_id);//delete only from UI
            // Navigate('/admin')
            window.location.reload() //to force the document to be fetced from the web server again.
        } catch (error) {
            console.log(error)
        }
    }

    //When we reject ped registration - the record deletes in application_cat_reg table (maybe better to change status?)
    const declinePedigree = async () => {
        try {
            await deleteApplication(cat_id, currentUserId).then(() => onDelete(cat_id))
            // Navigate('/admin')
            window.location.reload() //to force the document to be fetced from the web server again.
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="admin__item">
            {/* {console.log("CatNotRegistered.jsx rendered")} */}
            <h2>Full name: {cat_name_cattery_prefix}{cat_name} - CatID: {cat_id} - Application ID: {application_id}</h2>
            <p>Gender: {sex}</p>
            <p>Birthday: {fdate}</p>
            <p>Breed: {breed}</p>
            {breeder_first_name ? (
                <p>Breeder: {breeder_last_name} {breeder_first_name}</p>

            ) : (
                <p>Breeder (not registered): {breeder_not_user}</p>
            )}

            <div className="admin_ped">
                <p>Pedigree # {num}</p>
                <p>Pedigree issued by: {issued_by}</p>
                <p>Pedigree system: {pedigree_system_abb}</p>
            </div>
            <button className='profile-button' id="openPedigreeAdmin" onClick={onClickAdmin}>
                <img className="admin__pedigree-img" src={pedigree_img_url} alt='pedigree'></img>
            </button>
            {
                ReactDOM.createPortal(
                    <DialogModalAdmin dialogOpenAdmin={dialogOpenAdmin} onCloseDialogAdmin={onCloseDialogAdmin} img={pedigree_img_url} />,
                    document.getElementById('modal-root')
                )
            }
            <button className="joinButton" onClick={submitPedigree}>Confirm the pedigree is correct</button>
            <button className="delete" onClick={declinePedigree}>Decline the pedigree</button>
        </div>
    )
}

CatNotRegistered.defaultProps = {
    onDelete: () => { }
}
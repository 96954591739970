import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './DocumentsTab.scss'
import { DialogModal } from './DialogModal';
// import { Clue } from '../../../components/ui/Clue/Clue';
// import { sendToRegistration } from '../../../services/api';
// import { CatContext } from '../../../context/CatContext';
import { LanguageContext } from '../../../context/langContext.js';
import { debug, info, warn, error } from "../../../services/logger.js";


const DocumentsTab = (props) => {
    // console.log('DocumentsTab | props = ', props)
    const { catProfileValue } = props;
    info('DocumentsTab | catProfileValue (from props) = ', catProfileValue)
    const { metric, pedigree, error403 } = catProfileValue
    // const { data: cat } = useContext(CatContext);

    const [dialogOpen, setShowDialog] = useState(false);
    const [dialogOpenMetric, setShowDialogMetric] = useState(false);
    const [dialogOpenReg, setShowDialogReg] = useState(false);
    const { translations } = useContext(LanguageContext)

    const editMode = catProfileValue?.editMode;
    // editMode can be { 'edit_public_locked', 'edit_reg_locked', 'edit_parent_partial_locked', 'edit_unlocked' } - from backend CatController
    // console.log('CatProfileUp | catProfileValue.editMode = ', editMode)

    //button to see a pedigree
    const onClickLook = () => {
        setShowDialog(true);
    };

    //Send cat to registration AND show a model window
    //Need to check if the application is exist in DB!!!
    // const onClickReg = async () => {
    //     try {
    //         await sendToRegistration(catId, pedigree.id)//it makes a request to my backend=server
    //     } catch (err) {
    //         error("DocumentsTab.jsx | onClickReg, err: ", err)
    //         console.err("DocumentsTab.jsx | onClickReg, err: ", err)
    //     }
    //     setShowDialogReg(true);
    //     // window.location.reload() //to force the document to be fetced from the web server again.
    //     // Without it, when we delete a cat - it deletes in DB, but still in frontend.In future it better to do it using REDUX or some other managment tools
    // };

    const onClickLookMetric = () => {
        setShowDialogMetric(true);
    };

    const onCloseDialog = () => {
        setShowDialog(false);
    };

    const onCloseDialogMetric = () => {
        setShowDialogMetric(false);
    };

    const onCloseDialogReg = () => {
        setShowDialogReg(false);
        window.location.reload() //to force the document to be fetced from the web server again.
    };

    // const Metrica = ({ doc }) => {
    //     console.log('DocumentsTab.jsx | Metrica | doc =  ', doc)
    //     // if (doc === "metrica") {
    //     //     doc = metric
    //     // } else if (doc === "pedigree") doc = pedigree
    //     // console.log('metric.id', metric.id)
    //     if (!doc?.id) {
    //         return <Link
    //             to={{ pathname: `./metric/new` }}
    //             state={{ entityType: 'metric', isEdit: false }}
    //         >
    //             <button className='profile-button'>Add cat metric</button></Link>
    //     } else {

    //         return (
    //             <div className='metric'>
    //                 <div className='metric-header'>
    //                     <h3 className='inline-block'> {translations.metric}</h3>
    //                     <Link
    //                         className='profile-button'
    //                         // className='inline-block link'
    //                         to={{ pathname: `./metric/${doc?.id}` }}
    //                         state={{ entityType: 'metric', isEdit: true }}>
    //                         Edit
    //                     </Link>
    //                 </div>
    //                 {doc?.id ? (
    //                     <div className="metric-block">
    //                         <img
    //                             src={doc?.metric_img_url}
    //                             alt="cat_metric"
    //                             width='80px'
    //                             onClick={onClickLookMetric}
    //                             style={{ cursor: 'pointer' }}
    //                         />
    //                         <div className='metric-text'>
    //                             <div>
    //                                 <span className='text-label'> {translations.number}</span>
    //                                 <span className='text-main'>{metric.metric_num ? metric.metric_num : 'no metric number'}</span>
    //                             </div>
    //                             {/* <Link

    //                                 className='inline-block link'
    //                                 to={{ pathname: `./metric/${metric?.id}` }}
    //                                 state={{ entityType: 'metric', isEdit: true }}>
    //                                 {metric.metric_num ? metric.metric_num : 'no metric number'}
    //                             </Link> */}
    //                             {/* <Link className='inline-block link' to={`./edit-metric/${metric_id}`}>{metric_num}</Link> */}
    //                             <div>
    //                                 <span className='text-label'>{translations.issued_by}</span>
    //                                 <span className='text-main'>{metric.issued_by}</span>
    //                             </div>
    //                             <div>
    //                                 <span className='text-label'>{translations.system}</span>
    //                                 <span className='text-main'>{metric.abb}</span>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 )
    //                     : (
    //                         <Link
    //                             to={{ pathname: `./metric/new` }}
    //                             state={{ entityType: 'metric', isEdit: false }}
    //                         >
    //                             <button className='profile-button'>Add a metric</button></Link>
    //                     )
    //                 }
    //             </div>
    //         )
    //     }
    // }
    const DocumentComponent = ({ doc }) => {
        debug('DocumentsTab.jsx | DocumentComponent | doc = ', doc);

        if (!doc) return null; // Prevent crashes if doc is undefined

        // Detect document type dynamically
        const isMetric = 'metric_num' in doc;  // True if it's a metric document
        // const isPedigree = 'pedigree_num' in doc; // True if it's a pedigree document

        // Rename properties dynamically
        const {
            id,
            metric_num,
            pedigree_num,
            metric_img_url,
            pedigree_img_url,
            issued_by,
            abb,
        } = doc;

        // Universal properties
        const num = metric_num || pedigree_num;  // Use the correct number
        const img_url = metric_img_url || pedigree_img_url || doc?.img_url;  // Prioritize available image

        if (!id) {
            return (<div> No document provided</div>)
        } else {

            return (
                <div className='metric'>
                    <div className='metric-header'>
                        <h3 className='inline-block'> {isMetric ? translations.metric : translations.pedigree}</h3>
                        {editMode === 'edit_unlocked' ?
                            (<Link
                                className='profile-button'
                                // className='inline-block link'
                                to={{ pathname: `./${isMetric ? 'metric' : 'pedigree'}/${id}` }}
                                state={{ entityType: isMetric ? 'metric' : 'pedigree', isEdit: true }}>
                                {translations.edit}
                            </Link>
                            ) : (
                                <>
                                </>
                            )
                        }
                    </div>
                    {img_url ? (
                        <div className="metric-block">
                            <img
                                src={img_url}
                                alt="cat document (metric or pedigree)"
                                width='80px'
                                onClick={isMetric ? onClickLookMetric : onClickLook}
                                style={{ cursor: 'pointer' }}
                            />
                            <div className='metric-text'>
                                <div>
                                    <span className='text-label'> {translations.number}</span>
                                    <span className='text-main'>{num ? num : isMetric ? 'no metric number' : 'no pedigree number'}</span>
                                </div>
                                {/* <Link

                                    className='inline-block link'
                                    to={{ pathname: `./metric/${metric?.id}` }}
                                    state={{ entityType: 'metric', isEdit: true }}>
                                    {metric.metric_num ? metric.metric_num : 'no metric number'}
                                </Link> */}
                                {/* <Link className='inline-block link' to={`./edit-metric/${metric_id}`}>{metric_num}</Link> */}
                                <div>
                                    <span className='text-label'>{translations.issued_by}</span>
                                    <span className='text-main'>{issued_by}</span>
                                </div>
                                <div>
                                    <span className='text-label'>{translations.system}</span>
                                    <span className='text-main'>{abb}</span>
                                </div>
                            </div>
                        </div>
                    )
                        : (
                            <Link
                                to={{ pathname: `./metric/new` }}
                                state={{ entityType: 'metric', isEdit: false }}
                            >
                                <button className='profile-button'>Add a metric</button></Link>
                        )
                    }
                </div>
            )
        }
    }

    // const Pedigree = () => {
    //     if (!pedigree?.id) {
    //         return (
    //             <React.Fragment>
    //                 <Link
    //                     to={{ pathname: `./pedigree/new` }}
    //                     state={{ entityType: 'pedigree', isEdit: false }}
    //                 >
    //                     <button className='profile-button'>Add pedigree</button></Link>
    //                 <Clue text={'To register a cat you should fill all the information about a cat before.'} />
    //             </React.Fragment>
    //         )

    //         //if application sent and NOT solved - show 'Application has been sent' 
    //     } else if (pedigree?.id > 0 && cat?.registered === 0 && cat?.breeder_first_name !== null && applicationStatus?.status !== 'solve' && applicationStatus.id > 0) {
    //         return (
    //             <React.Fragment>
    //                 <details open>
    //                     <summary>
    //                         <span className='big inline-block'> Pedigree</span>
    //                     </summary>
    //                     <div className="metric">
    //                         <div className='metric-text'>
    //                             <div className='inline-block'> {pedigree.num}</div>
    //                             <span className='inline-block gray '> Issued by</span>
    //                             <span className='inline-block'> {pedigree.issued_by}</span>
    //                             <span className='inline-block'> {pedigree.abb}</span>
    //                             <button className='profile-button' id="open" onClick={onClickLook}>Look</button>
    //                             <button className='profile-button margin-button' id="openReg" disabled={true} >Application has been sent</button>
    //                             <Clue text={'You can not edit the pedigree after sending to register'} />
    //                         </div>
    //                     </div>
    //                 </details>
    //             </React.Fragment>
    //         )

    //         //  Ready to register! 
    //         // Requirements: 
    //         //  1) all info about cat (including breeder_first_name !== null)
    //         //  2) all info about pedigree document (pedigrees_id - is truthy)
    //         //  3) NOT registered (!registered)
    //         //  4) NO current application (!applicationStatus.id)
    //     } else if (pedigree.id && (cat.breeder_first_name || cat.breeder_not_user) && !applicationStatus.id && !cat.registered) {
    //         return (
    //             <React.Fragment>
    //                 <details open>
    //                     <summary>
    //                         <span className='big inline-block'> Pedigree</span>
    //                     </summary>
    //                     <div className="metric">
    //                         <div className='metric-text'>
    //                             <Link className='inline-block link' to={{ pathname: `./pedigree/${pedigree.id}` }} state={{ entityType: 'pedigree', isEdit: true }}>
    //                                 {pedigree.pedigree_num ? pedigree.pedigree_num : 'no pedigree number'}
    //                             </Link>
    //                             <span className='inline-block gray '> Issued by</span>
    //                             <span className='inline-block'> {pedigree.issued_by}</span>
    //                             <span className='inline-block'> {pedigree.abb}</span>
    //                             <button className='profile-button' id="open" onClick={onClickLook}>Look</button>
    //                             {/* <Clue text={'To register a cat you should fill all the information about a cat before'} /> */}
    //                             <button className='profile-button margin-button' id="openReg" onClick={onClickReg}>Register a cat</button>
    //                         </div>
    //                     </div>
    //                 </details>
    //             </React.Fragment>
    //         )
    //         // if we added pedigree and NOT registered. 
    //         // if registered.
    //     } else if (pedigree.id > 0) {
    //         return (
    //             <div className='metric'>
    //                 {/* <summary>
    //                         <span className='big inline-block'> Pedigree</span>
    //                     </summary> */}
    //                 <div className='metric-header'>
    //                     <h3 className='inline-block'> {translations.pedigree}</h3>
    //                     {cat.registered === 0 ? (
    //                         <Link
    //                             className='profile-button'
    //                             // className='inline-block link'
    //                             to={{ pathname: `./pedigree/${pedigree?.id}` }}
    //                             state={{ entityType: 'pedigree', isEdit: true }}>
    //                             Edit
    //                         </Link>
    //                     ) : (
    //                         <button className='profile-button' disabled>Edit</button>
    //                     )
    //                     }
    //                 </div>
    //                 <div className="metric-block">
    //                     <img
    //                         src={pedigree?.pedigree_img_url}
    //                         alt="cat_pedigree"
    //                         width='80px'
    //                         onClick={onClickLook}
    //                         style={{ cursor: 'pointer' }}
    //                     />
    //                     <div className='metric-text'>
    //                         <div>
    //                             <span className='text-label'> {translations.number}</span>
    //                             <span className='text-main'>{pedigree.pedigree_num ? pedigree.pedigree_num : 'no pedigree number'}</span>
    //                         </div>
    //                         {/* <Link

    //                                 className='inline-block link'
    //                                 to={{ pathname: `./metric/${metric?.id}` }}
    //                                 state={{ entityType: 'metric', isEdit: true }}>
    //                                 {metric.metric_num ? metric.metric_num : 'no metric number'}
    //                             </Link> */}
    //                         {/* <Link className='inline-block link' to={`./edit-metric/${metric_id}`}>{metric_num}</Link> */}
    //                         <div>
    //                             <span className='text-label'>{translations.issued_by}</span>
    //                             <span className='text-main'>{pedigree.issued_by}</span>
    //                         </div>
    //                         <div>
    //                             <span className='text-label'>{translations.system}</span>
    //                             <span className='text-main'>{pedigree.abb}</span>
    //                         </div>
    //                         {cat.registered === 0 &&
    //                             <span className='clue-text'>To register a cat you should fill all the information about a cat before</span>
    //                         }
    //                         {/* <Clue text={'To register a cat you should fill all the information about a cat before'} /> */}
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }

    //     // REGISTERED registered === 1
    //     else {
    //         return (
    //             <React.Fragment>
    //                 {/* <details open>
    //                     <summary>
    //                         <span className='big inline-block'> Pedigree</span>
    //                     </summary>
    //                     <div className="metric">
    //                         <div className='metric-text'>
    //                             <div className='inline-block'> {pedigree.num}</div>
    //                             <span className='inline-block gray '> Issued by</span>
    //                             <span className='inline-block'> {pedigree.issued_by}</span>
    //                             <span className='inline-block'> {pedigree.abb}</span>
    //                             <button className='profile-button' id="open" onClick={onClickLook}>Look</button>
    //                             <p><strong className='registered'>Registered</strong><small> - the cat can not be updated or deleted</small></p>
    //                         </div>
    //                     </div>
    //                 </details> */}
    //             </React.Fragment>
    //         )
    //     }
    // }

    if (error403) {
        return (
            <div>
                {error403}
            </div>
        )
    }
    if (editMode === 'edit_unlocked') {

        return (
            <div className='cat-tab'>
                {/* <Metrica doc={metric}/>
            <Pedigree doc={pedigree}/> */}
                {metric.id
                    ? <DocumentComponent doc={metric} />
                    : (
                        <>
                            <Link
                                to={{ pathname: `./metric/new` }}
                                state={{ entityType: 'metric', isEdit: false }}
                            >
                                <button className='profile-button'> {`Add metric`}</button>
                            </Link>
                        </>
                    )}

                {pedigree.id
                    ? <DocumentComponent doc={pedigree} />
                    : (
                        <>
                            <Link
                                to={{ pathname: `./pedigree/new` }}
                                state={{ entityType: 'pedigree', isEdit: false }}
                            >
                                <button className='profile-button'> {`Add pedigree`}</button></Link>
                        </>
                    )}
                {ReactDOM.createPortal(
                    <DialogModal
                        dialogOpen={dialogOpenReg}
                        onCloseDialog={onCloseDialogReg}
                        num={'The registration application was sent'}
                        title={'Sent'} />,
                    document.getElementById('modal-root')
                )}
                {ReactDOM.createPortal(
                    <DialogModal
                        dialogOpen={dialogOpenMetric}
                        onCloseDialog={onCloseDialogMetric}
                        img={metric?.metric_img_url && metric?.metric_img_url}
                        num={metric?.metric_num}
                        title={'Metric'} />,
                    document.getElementById('modal-root')
                )}
                {ReactDOM.createPortal(
                    <DialogModal
                        dialogOpen={dialogOpen}
                        onCloseDialog={onCloseDialog}
                        img={pedigree?.pedigree_img_url && pedigree?.pedigree_img_url}
                        num={pedigree?.pedigree_num}
                        title={'Pedigree'} />,
                    document.getElementById('modal-root')
                )}
            </div>
        )
    }
    else if (editMode === 'edit_reg_locked' || editMode === 'edit_parent_partial_locked') {
        return (
            <div className='cat-tab'>
                {/* <Metrica doc={metric}/>
            <Pedigree doc={pedigree}/> */}
                {metric.id
                    ? <DocumentComponent doc={metric} />
                    : (
                        <></>
                    )}

                {pedigree.id
                    ? <DocumentComponent doc={pedigree} />
                    : (
                        <>
                            {/* <Link
                                to={{ pathname: `./pedigree/new` }}
                                state={{ entityType: 'pedigree', isEdit: false }}
                            >
                                <button className='profile-button'> {`Add pedigree`}</button></Link> */}
                        </>
                    )}
                {ReactDOM.createPortal(
                    <DialogModal
                        dialogOpen={dialogOpenReg}
                        onCloseDialog={onCloseDialogReg}
                        num={'The registration application was sent'}
                        title={'Sent'} />,
                    document.getElementById('modal-root')
                )}
                {ReactDOM.createPortal(
                    <DialogModal
                        dialogOpen={dialogOpenMetric}
                        onCloseDialog={onCloseDialogMetric}
                        img={metric?.metric_img_url && metric?.metric_img_url}
                        num={metric?.metric_num}
                        title={'Metric'} />,
                    document.getElementById('modal-root')
                )}
                {ReactDOM.createPortal(
                    <DialogModal
                        dialogOpen={dialogOpen}
                        onCloseDialog={onCloseDialog}
                        img={pedigree?.pedigree_img_url && pedigree?.pedigree_img_url}
                        num={pedigree?.pedigree_num}
                        title={'Pedigree'} />,
                    document.getElementById('modal-root')
                )}
            </div>
        )

    }
}


// PedigreeTab.propTypes = {

// }

export default DocumentsTab

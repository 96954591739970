import { useContext, useEffect, useState } from "react"
import CatCardSell from "../../components/CatCardSell/CatCardSell"
import { LanguageContext } from "../../context/langContext";
import { fetchBreeds, fetchCatSaleFilter, fetchCatSalesAll } from "../../api";
import { formatValuesForSelect } from "../../utils/formatValuesForSelect";
import Select from 'react-select';
import './market.scss';

const Market = () => {

    const { translations } = useContext(LanguageContext)

    const [breeds, setBreeds] = useState([]);
    const [salesCatAll, setSalesCatAll] = useState([]);
    const [allBreeds, setAllBreeds] = useState([]);
    const [breedChosen, setBreedChosen] = useState(null)
    const [catFiltered, setCatFiltered] = useState([]);
    const [formInput, setFormInput] = useState({
        breed: 'all',
        country: '',
        // priceRange: { min: '', max: '' },
        // rating: '',
        // sortBy: ''
    });
    const [noFilteredCats404, setNoFilteredCats404] = useState('')

    // console.log('Market | salesCatAll = ', salesCatAll);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '2px solid #C38490',
            boxShadow: state.isFocused ? '0 0 0 1px #C38490' : null,
            '&:hover': {
                border: '2px solid #C38490'
            },
            borderRadius: '8px',
            padding: '5px',
            minWidth: '280px'
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '8px',
            marginTop: '5px',
            minWidth: '280px'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#C38490' : state.isFocused ? '#eddadd' : null,
            color: state.isSelected ? 'white' : 'black',
            padding: '10px 20px',
            width: '100%'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#C38490',
            width: '100%'
        })
    };

    useEffect(() => {
        const allBreedsOption = { value: 'all', label: translations?.market.filterSelect }
        const fetchAllBreeds = async () => {
            const res = await fetchBreeds();
            // console.log('res from fetchAllBreeds', res)
            setAllBreeds(res);
            let breed = formatValuesForSelect(res, 'breed', `ems`);
            const options = [allBreedsOption, ...breed]
            // console.log('breed', breed)
            setBreeds(options)
        }
        const fetchSales = async () => {
            const { success, data: resSales, message } = await fetchCatSalesAll();
            // console.log('Market.jsx > resSales = ', resSales)
            setSalesCatAll(resSales); //[{}, {}]
        };
        fetchAllBreeds()
        fetchSales();
    }, [translations?.market.filterSelect])

    const onChangeBreed = (selectedOption) => {
        setFormInput((prevFormInput) => ({ ...prevFormInput, breed: selectedOption.value }));
    }
    // For regular select (not react-select)
    // const onChangeBreed = (selectedOption) => {
    //     // console.log('selectedOption.target.name = ', selectedOption.target.name) //breed
    //     // console.log('selectedOption.target.value = ', selectedOption.target.value) // 1
    //     const { name, value } = selectedOption.target
    //     // setFormInput(({ ...formInput, [name]: value }))
    //     setFormInput((prevFormInput) => ({ ...prevFormInput, [name]: value })); //ChatGPT
    // }

    const handleSubmit = async (e) => {
        // console.log('formInout from handleSubmit =', formInput)
        e.preventDefault();
        if (formInput.breed === 'all') {
            setCatFiltered(salesCatAll);
            setBreedChosen(null);
            setNoFilteredCats404('');
        } else {
            const queryString = new URLSearchParams(formInput).toString();
            // console.log('queryString:', queryString) //queryString: breed=1&priceRange=%5Bobject+Object%5D&rating=&sortBy=
            const response = await fetchCatSaleFilter(queryString);
            // console.log('Market.jsx > handleSubmit > response = ', response)
            if (response.success && response.data.length > 0) {

                setCatFiltered(response.data);
                // console.log("response from fetch from handleSumbit =", response[0])
                // if (formInput.breed !== 'all') {
                // const breedIdChoosen = parseInt(response[0].id, 10);
                const breedIdChosen = parseInt(formInput.breed, 10);
                // console.log('breedIdChoosen = ', breedIdChosen)
                const breedChosenFilter = allBreeds?.find((element) => element?.id === breedIdChosen)
                // console.log('breedChosenFilter[0]', breedChosenFilter[0])
                setBreedChosen(breedChosenFilter);
                setNoFilteredCats404('');
            } else {
                // setBreedChosen(null);
                // } else {
                setNoFilteredCats404('We are sorry! No such cats in database')
                setCatFiltered([]);
                setBreedChosen(null);
            }
        }

        // setBreedChosen(breedChosenFilter[0]);
        // handle the data (e.g., update state to display the products)
    };
    // console.log('breedChosen = ', breedChosen);
    // const header1 =
    //     `${translations?.market?.h1} - ${breedChosen?.breed
    //         ? `${breedChosen?.breed} ${translations?.breed}`
    //         : `${translations.all} ${translations.breeds}`}`
    const header1 = noFilteredCats404
        ? 'No breeds'
        : `${translations?.market?.h1} - ${breedChosen?.breed
            ? `${breedChosen?.breed} ${translations?.breed}`
            : `${translations.all} ${translations.breeds}`}`
    // console.log('salesCatAll = ', salesCatAll)
    // console.log("breeds = ", breeds)
    return (
        <div className="market">
            <h1>{header1}</h1>
            <div className="market__filter-breed">
                <form onSubmit={handleSubmit}>
                    {/* <select name="breed" onChange={onChangeBreed} value={formInput.breed}>
                        <option value="">{translations?.market.filterSelect}</option>
                        {breeds.map((br) => (
                            <option key={br.value} value={br.value}>{br.label}</option>
                        ))}
                    </select> */}
                    <div className="market__filter">
                        <Select
                            name="breed"
                            options={breeds}
                            styles={customStyles}
                            placeholder={translations?.market.filterSelect}
                            defaultValue={{ value: 'all', label: translations?.market.filterSelect }}
                            onChange={onChangeBreed}
                        />
                        {/* 
                    <input
                    type="number"
                    name="min"
                    placeholder="Min Price"
                    onChange={handlePriceRangeChange}
                    value={formInput.priceRange.min}
                    />
                    <input
                    type="number"
                    name="max"
                    placeholder="Max Price"
                    onChange={handlePriceRangeChange}
                    value={formInput.priceRange.max}
                    />
                    
                    <select name="rating" onChange={handleInputChange} value={formInput.rating}>
                    <option value="">Select Rating</option>
                    <option value="1">1 Star</option>
                    <option value="2">2 Stars</option>
                    </select>
                    
                    <select name="sortBy" onChange={handleInputChange} value={formInput.sortBy}>
                    <option value="">Sort By</option>
                    <option value="price">Price</option>
                    <option value="rating">Rating</option>
                    </select> */}

                        <button type="submit">{translations.market.apply_filters}</button>
                    </div>
                    <div> {noFilteredCats404 ? noFilteredCats404 : ''}</div>
                </form>

            </div>
            {noFilteredCats404 === '' ? (
                < div className="market__cats-block">
                    {catFiltered.length > 0 ? (
                        catFiltered.map((saleCat, index) => (
                            <CatCardSell key={saleCat.id || index} catId={saleCat.id} />
                        ))
                    ) : (
                        salesCatAll.map((saleCat, index) => (
                            <CatCardSell key={saleCat.id || index} catId={saleCat.id} />
                        )))
                    }
                </div>
            ) : null}
        </div >
    )
}

export default Market

// import { useEffect } from 'react';
import { useState } from 'react';
// import { useContext } from 'react'
//============
import "./allCats.scss"
// import { fetchCats } from '../../api';

const Main = () => {

  const [cats, setCats] = useState([]);

  // const { currentUser } = useContext(useAuth);
// const auth = useAuth();

  // useEffect(() => {
  //   const fetchAllCats = async () => {
  //     try {
  //       const res = await fetchCats() //we made a request to bring us info from front on this http address
  //       setCats(res); //res.data come already
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchAllCats()
  // }, [])

  return (
    <div className='allcats'>
      <h1>All cats</h1>
      {/* <div>
        {!auth.currentUser && <Link className="joinButton" to="/Join">Join us!</Link>}
      </div> */}
      <div className="catswrap">
        <div className="block__row">
          {cats.map((cat) => (
            <div className="block__column" key={cat.id}>
              <Cat1 cat_name={cat.cat_name} sex={cat.sex} cat_birthdate={cat.cat_birthdate} id={cat.id} />
            </div>
          ))}
        </div>
        {/* <button className='but'><Link to="/add">Add new cat</Link></button> */}
      </div>
    </div>
  )
}

const Cat1 = (props) => {
  // const handleDelete = async (id) => {
  //   try {
  //     await axios.delete("http://localhost:8800/cats/" + id)//it makes a request to my backend=server
  //     window.location.reload() //to force the document to be fetced from the web server again.
  //     // Without it, when we delete a cat - it deletes in DB, but still in frontend.In future it better to do it using REDUX or some other managment tools
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  return (
    <div className="block__item">
      {/* {props.cover && <img className='catimg' src={props.cover} alt="" />} */}
      <h2>{props.cat_name}</h2>
      <p>{props.sex}</p>
      <span>🎂 Birthday : {props.cat_birthdate}</span>
      {/* <button className="delete" onClick={() => handleDelete(props.id)}>Delete</button>
      <button className="update"><Link to={`/update/${props.id}`}>Update</Link></button> */}
    </div>
  )
}

export default Main

import http from '../services/httpService';
// import { debug, error } from '../services/logger';

////// CAT CHANGE OWNER
export const postCatChangeOwner = async (catId) => {
    // console.log('api.js catChangeOwner filteredValue = ', catId) //userId we take from userInfo from validateMiddle
    const result = http.post("/cat-change-owner", catId)
    return result
};

export const fetchPrevOwnerFromCatChangeOwner = async (catId) => {
    const { data } = await http.get(`/cat-change-owner/${catId}`);
    return data
};

export const postCatChangeOwnerFromPrev = async (data) => {
    const result = http.post(`/cat-change-owner/cat-change-owner-from-prev`, data) //{catId: 1, prevOwner: 6}
    return result
};
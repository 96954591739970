import React, { useContext, useState } from 'react'
import FormInput from '../../components/FormInput/FormInput';
// import { Link } from 'react-router-dom';
import { RecoveryContext } from '../IndexRestore';
import { changePasswordInDB} from '../../api';
import { LanguageContext } from "../../context/langContext";

function Reset() {
    const { setPage, email } = useContext(RecoveryContext)
    const [value, setValue] = useState({
        password: "",
        confirmPassword: "",
        err: ""
    });
    const { translations } = useContext(LanguageContext)

    // our object in useState - used in the first time rendering
    const inputs1 = [
        {
            id: 1,
            name: "password",
            type: "text",
            placeholder: translations.reset?.enterNewPassword,
            errorMessage: translations.reset?.passwordRequirement,
            label: translations.reset?.newPassword,
            pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^*]{8,20}$`, //`^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^＆*]{8,20}$`,
            required: true,
            inputId: 'password',
            maxLength: 100
            // className:"joinInput"
        },
        {
            id: 2,
            name: "confirmPassword",
            type: "text",
            placeholder: translations.reset?.confirmNewPassword,
            errorMessage: translations.reset?.passwordRequirement,
            label: translations.reset.newPasswordConfirm,
            pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^*]{8,20}$`, //`^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^＆*]{8,20}$`,
            required: true,
            inputId: 'confirm-password',
            maxLength: 100
        },
    ]

    async function changePassword(e) {
        e.preventDefault();
        console.log('ChangePassword evoked, inputs.email = ', value.password)
        if (value.password && value.confirmPassword) {
            if (value.password === value.confirmPassword) {
                console.log('password=confirm password! Value.password = ', value.password)
                setValue((prevState) => ({ ...prevState, err: '' }));
                const valuePassword = value.password
                const res = await changePasswordInDB({valuePassword, email});
                console.log('res.data.message', res.data.message)
                setPage("recovered")

                
            } else {
                console.log('Password and confirm password are NOT equal!')
                setValue((prevState) => ({ ...prevState, err: translations.reset.passwordNotMatch}));
            }
        }

    };

    // const [err, setError] = useState(null);
    // const location = useLocation();
    // console.log(location.state.path)
    // const redirectPath = location.state?.path || '/';

    const handleChange = e => { setValue(prev => ({ ...prev, [e.target.name]: e.target.value })) };


    return (
        <div className="login">
            <form onSubmit={changePassword} className="joinform">
                <h1>{translations.reset.changePassword}</h1>
                <p>{translations.reset.forUser} {email}</p>
                {inputs1.map((input) => (
                    <FormInput key={input.id} {...input} value={inputs1[input.name]} onChange={handleChange} />
                ))
                }
                {/* {err && <div className='errorMessage'>{err}</div>} */}
                {/* <span><input type='checkbox' /> I accept the <Link to="/terms">Terms and Conditions</Link></span> */}
                <button className='joinButton' type='submit'>{translations.reset.resetPassword}</button>
                {value.err && <p className='errorMessage'>{value.err}</p>}
                {/* <span><Link to="/restore">Restore password</Link> </span> */}
            </form>
        </div>
    )
}

export default Reset

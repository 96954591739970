
import http from '../services/httpService';
// import { debug, error } from '../services/logger';

////// COUNTRIES
export const fetchCountries = async () => {
    try {
        const res = await http.get('/countries/en_ru')
        // console.log("API.js | fetchCountries | res =", res);
        if (res.status >= 200 && res.status < 300) {
            // console.log(" API.js | getMyCats | res.data =", res.data)
            return res.data
        }
        else {
            console.error(`Error: Received status code ${res.status} from /countries/en`);
            throw new Error(`Could not fetchCountries, res.status = ${res.status}`)
        }
    } catch (error) {
        console.error(`Failed fetchCountries: ${error.message}`);
        throw new Error('Failed fetchCountries');
    }
};

// logger.js
// const isProduction = process.env.NODE_ENV === "production";

const SERVER_URL = `${process.env.REACT_APP_API_URL}/logs/client`; // server URL
console.log('logger.js > process.env.REACT_APP_API_URL = ', process.env.REACT_APP_API_URL )

const logToServer = async (level, message, meta = {}) => {
  try {
    // console.log('services | logger | logToServer evoked')
    // const fullMessage = `${context}: ${message}`; // Include context in the message
    
    // Pretty-print the meta object with indentation
    // const formattedMeta = JSON.stringify(meta, null, 2);
    // const fullMessage = `${message} ${formattedMeta}`;
    await fetch(SERVER_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ level, message, meta }),
      // body: JSON.stringify({ level, message, meta: formattedMeta }),
    });
  } catch (error) {
    console.error("Failed to send log to server", error);
  }
};

export const info = (message, meta) => logToServer("info", message, meta);
export const warn = (message, meta) => logToServer("warn", message, meta);
export const error = (message, meta) => logToServer("error", message, meta);
export const debug = (message, meta) => logToServer("debug", message, meta);

// export const info = (context, message, meta) => logToServer("info", context, message, meta);
// export const warn = (context, message, meta) => logToServer("warn", context, message, meta);
// export const error = (context, message, meta) => logToServer("error", context, message, meta);
// export const debug = (context, message, meta) => logToServer("debug", context, message, meta);


// Usage: 
// info("Profile.jsx | resMyCats", "User logged in", { userId: 123, actionName: "login" });
// This log entry would look like this in the log file:
// [2024-11-10T12:34:56.789Z] [INFO]: Profile.jsx | resMyCats User logged in {"userId":123,"actionName":"login"}














// import * as Sentry from "@sentry/react";

// // Debugging monitior initialisation
// function init() {

//     Sentry.init({
//         dsn: "https://c42b4b5ecfd84365b2018c506af2ec15@o4505448510914560.ingest.sentry.io/4505448561377280",
//         integrations: [
//             new Sentry.BrowserTracing({
//                 // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//                 tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//             }),
//             new Sentry.Replay(),
//         ],
//         // Performance Monitoring
//         tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//         // Session Replay
//         replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//         replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//     });

// }

// function log(error) {
//     Sentry.captureException(error);

// }

// const logService = {
//     init,
//     log
// };

// export default logService;
import { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../context/authContext.js';

//My modules//
import "./sellOrder.scss";
// import CatCard from "../CatCard/CatCard.jsx";
// import Pagination from '../ui/pagination.jsx';
// import { paginate } from '../../utils/paginate.js';
// import ListGroup from '../ui/ListGroup.jsx'
import { getOrders } from '../../api';
import { LanguageContext } from '../../context/langContext.js';

const SellOrder = () => {
    const { translations } = useContext(LanguageContext)

    // const [notMyCats, setNotMyCats] = useState([]);
    const [sellOrders, setSellOrders] = useState([]); //an array of my cats
    const [numberOfCats, setNumberOfCats] = useState(0)
    // const [numberOfNotMyCats, setNumberOfNotMyCats] = useState(0)
    const [selectedSexFilter, setSelectedSexFilter] = useState('all')
    // const [selectedFilterMy, setSelectedFilterMy] = useState('All')
    const [currentPage, setCurrentPage] = useState(1);
    // const [currentArrayOfCats, setCurrentArrayOfCats] = useState([]);
    // const [pageSize, setPageSize] = useState(5);
    const pageSize = 5;
    // const [filtered, setFiltered] = useState([]);
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;

    // const sexFilterNames = ['all', 'female', 'male'];

    useEffect(() => {
        const fetchDataOrders = async () => {
            try {
                const {data:resAllMyOrders} = await getOrders(); //{userId: 10, isSell: true}
                // console.log('resAllMyOrders:', resAllMyOrders);
                setSellOrders(resAllMyOrders);
                setNumberOfCats(resAllMyOrders.length)

            } catch (error) {
                console.log(error);
            }
        }
        fetchDataOrders()
    }, [])

    // const handleSexFilterSelect = useCallback((filter) => {
    //     setSelectedSexFilter(filter);
    //     setCurrentPage(1);
    // }, []);

    // const handlePageChange = useCallback((page) => {
    //     setCurrentPage(page);
    // }, []);

    // const filteredCats = allCats.filter(allCats => {
    //     if (selectedSexFilter !== 'all' && allCats.sex !== selectedSexFilter) {
    //         return false;
    //     }
    //     return true
    // })

    const OrderItem = ({ item }) => {
        return (
            <div className='sell-order__item'>
                <div>
                    Order to buy ID {item?.orders_id} |
                    Order was created at: {item?.orders_created_at} |
                </div>
                <div className="sell-order__pet">
                    Cat ID {item?.orders_cat_id} |
                    Cat name: {item?.cat_name_prefix} {item?.cat_name} |
                    Delivery needed: {item?.is_delivery_needed === 0 ? "No" : "Yes"} |
                </div>
                <div>
                    Client ID: {item?.client_id} -
                    Client Name: {item?.user_first_name} {item?.user_last_name} -
                    Client email: {item?.client_email} -
                    Comment: {item?.comment}
                </div>
            </div>
        )

    }

    return (
        <div className="sell-order__container">
            <h1>{translations?.my.sell_orders}</h1>
            <div>
                {sellOrders.length > 0 ? (
                    <ol>
                        {sellOrders.map((item) => (
                            <li key={item?.orders_id}>
                                <OrderItem item={item} />
                            </li>
                        ))}
                    </ol>
                ) : (
                    <p>No sell orders available.</p>
                )}

            </div>
        </div>
    )
}

export default SellOrder;


////// CATTERIES

import http from '../services/httpService';
// import { debug, error } from '../services/logger';


export const postCattery = (values) => {
    return http.post(`/me/cattery`, values)
};

export const fetchMyCatteries = async (currentUserId) => {
    const { data } = await http.get(`/catteries?user=${currentUserId}`)
    // console.log('api.js | fetchMyCatteries | data', data)
    return data
};

export const fetchOneCattery = async (currentUserId, catteryId) => {
    const { data } = await http.get(`/catteries/${catteryId}/edit`, currentUserId)
    // console.log('api.js | fetchOneCattery | data', data)
    return data;
};

export const updateCattery = (catteryId, values) => {
    return http.put(`/catteries/${catteryId}`, values);
};
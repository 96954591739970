import http from '../services/httpService';
// import { debug, error } from '../services/logger';

////// AUTH
export const loginUser = async (credentials) => {
    try {
        // const res = await http.post(`/auth/login`, credentials);
        const res = await http.post(`/auth/login`, credentials, { withCredentials: true });
        // console.log('res from api.js from loginUser res = ', res)
        return { success: true, data: res.data };
    } catch (error) {
        console.log('API.js | loginUser | error = ', error)
        if (error.response && error.response.status === 400 && error.response.data === 'Wrong username or password!') {
            return { success: false, message: 'Wrong username or password. Please try again.' };
        } else if (error.response && error.response.status === 404 && error.response.data === 'User not found!') {
            return { success: false, message: 'User not found!' };
        } else {
            console.error('API.js | loginUser | ELSE Error:', error);
            return { success: false, message: 'An unexpected error occurred. Please try again later.' };
        }

    }
};

// version 1:
// export const join = (credentials) => {
//     return http.post(`/auth/register/`, credentials)
// };

//version 2:
export const join = async (credentials) => {
    const resjoin = await http.post(`/auth/register`, credentials, { withCredentials: true });
    // console.log('from client api.js join resjoin.data = ', resjoin.data);
    return resjoin;
};

export const logoutApi = async () => {
    // console.log('from client api.js join resjoin.data = ');
    const result = await http.post(`/auth/logout`, { withCredentials: true });
    return result;
};

export const checkAuthStatus = async () => {
    // console.log('checAuthStatus evoked from api.js')
    try {
        const response = await http.get('/auth/check-status', { withCredentials: true });
        //option is included to ensure that cookies (which may contain the user's session ID or auth token) are sent along with the request.
        // console.log('response.data from api from checkAuthStatus', response.data)
        return response.data;
    } catch (error) {
        console.error('Error during checking auth status: ', error);
        throw error;
    }
}

export const changePasswordInDB = async (cred) => {
    // console.log('password from changePasswordInDB: ', cred)
    const result = await http.put(`/auth/change-password`, cred)
    return result;
};

export const checkEmailExists = async (email) => {
    // console.log('email from api.js from checkEmailExists', email)
    const result = await http.get(`/auth/check-email-exists?email=${email}`) //The HTTP GET request does not usually send a request body. Instead, it sends data as query parameters or URL parameters.
    return result
};



//////// RECOVERY
export const SendRecoveryEmail = async (email, otp) => {
    // console.log('SendRecoveryEmail evoked')
    const result = http.post(`/send-recovery-email`, { OTP: otp, recipient_email: email })
    return result
};
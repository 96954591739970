
/////// COLOR

import http from '../services/httpService';
// import { debug, error } from '../services/logger';
export const fetchColor = async (catId) => {
    return await http.get(`/color/${catId}`);
};

export const putColor = async (values, newColor) => {
    return await http.put(`/color/${values.id}`, newColor)
};

export const postColor = async (catInsertedId, newColor) => {
    // console.log('API.js > postColor > newColor', newColor) // { main_ems: 'c'}
    const result = await http.post(`/color`, { cat_id: catInsertedId, ...newColor });
    // console.log('API.js > postColor > result.data', result.data) // 
    return result.data
};
import { useEffect, useState } from 'react'
import { fetchFather, fetchMother, fetchParents } from '../../api';
import { useParams } from 'react-router-dom';
import { debug } from '../../services/logger';

const initialState = {
    mother: {},
    father: {}
};
const useParents = () => {
    const catId = useParams().id;
    // console.log('useParents evoked! catId = ', catId)

    const [parents, setParents] = useState(initialState);
    // const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;
        // console.log('useParents useEffect catId = ', catId)

        const fetchCatRelatedData = async () => {

            try {
                const resDataParents = await fetchParents(catId); //cat_id, father_id, id, mother_id
                debug('useParents | useEffect, resDataParents', resDataParents);
                // console.log('useParents | useEffect, resDataParents', resDataParents);
                if (resDataParents) {
                    const { mother_id, father_id } = resDataParents;
                    const fetchTasks = [];

                    if (mother_id) {
                        fetchTasks.push(fetchMother(mother_id)); //fetchMother from cat table
                    }
                    if (father_id) {
                        fetchTasks.push(fetchFather(father_id)); //fethcFather from cat table
                    }

                    const results = await Promise.all(fetchTasks);
                    // console.log('useParents.jsx results =', results)

                    const updatedState = { ...initialState };
                    // Also, when using the Promise.all() with dynamic tasks, you should be careful about how you destructure results.
                    // If you're not always sure about the order or presence of results 
                    // (e.g., if mother_id doesn't exist but father_id does), then destructuring like[resMother, resFather] can be problematic.
                    // Instead of destructuring, you can access results based on the order of tasks:
                    let index = 0;
                    if (mother_id) {
                        updatedState.mother = results[index++] || {}; // access the first result
                    }

                    if (father_id) {
                        updatedState.father = results[index] || {};

                    } if (isMounted) {
                        setParents(updatedState);
                    }
                    // const [resMother, resFather] = await Promise.all(fetchTasks);
                    // console.log('useParents.jsx parents = ', parents)
                    // updatedState.mother = mother_id ? resMother[0] : {};
                    // updatedState.father = father_id ? resFather[0] : {};
                    // setState(prevState => ({ ...prevState, ...updatedState }));
                } else {
                    setParents(initialState);
                }
            } catch (error) {
                console.log(error)
                if (isMounted) setParents(initialState);
            }
        };

        if (catId) fetchCatRelatedData();

        const cleanUp = () => {
            // console.log('clean up function');
            isMounted = false;
        }
        return cleanUp;

    }, [catId])


    // console.log('useParents final parents = ', parents)
    return parents;
}

export default useParents
import { useState, useEffect, useCallback } from 'react';
import { fetchDocs, fetchApplications, fetchPrevOwnerFromCatChangeOwner } from '../api';
import { useAuth } from '../../src/context/authContext.js';
import { debug } from '../services/logger.js';

export const useFetchCatData = (catId) => {
    const auth = useAuth();
    const currentUserId = auth.currentUser?.id;
    debug("useFetchCatData | currentUserId", currentUserId)
    debug("useFetchCatData | catId", catId && catId)

    const [state, setState] = useState({
        metric: {},
        pedigree: {},
        applicationStatus: {},
        prevOwner: [],
    });
    const [error403, setError403] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchCatRelatedData = useCallback(async () => {
        if (!catId || !currentUserId) return;

        setLoading(true);
        try {
            const [docs, applicationStatus, prevOwner] = await Promise.all([
                fetchDocs(catId, currentUserId),
                fetchApplications(catId), //return object { status: 'not_found', application_id: null }
                fetchPrevOwnerFromCatChangeOwner(catId)
            ]);

            if (docs?.status === 403) setError403(docs?.data?.message);

            setState({
                metric: docs?.metric || {},
                pedigree: docs?.pedigree || {},
                applicationStatus: applicationStatus?.status || {},
                prevOwner: prevOwner || [],
            });
        } catch (error) {
            console.error('Error fetching cat data:', error);
        } finally {
            setLoading(false);
        }
    }, [catId, currentUserId]);

    useEffect(() => {
        if (catId) fetchCatRelatedData();
    }, [fetchCatRelatedData, catId]);

    debug("useFetchCatData.js | state: ", state)

    return { state, error403, loading };
};

// export default useFetchCatData;
import React, { useState, useEffect } from 'react'
import { UploadFile } from '../../components/UploadFile/UploadFile';
import { HandleUpload } from '../../components/UploadFile/HandleUpload';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './adduserphoto.scss';
import { fetchUser, postUserAva } from '../../api';

const AddUserPhoto = () => {
    
    const [user, setUser] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    
    const location = useLocation()
    const navigate = useNavigate()
    const userId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser
    const BASE_URL = process.env.REACT_APP_API_URL; //need only for UploadFile - latter maybe better to refactor
    
    axios.defaults.withCredentials = true;// !!!!!

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resData = await fetchUser()
                setUser(resData)
            }
            catch (err) { console.log(err) }
        }
        fetchData();
    }, [userId])

    const handleOnChangeFile = (file) => {
        setSelectedFile(file);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        const prefix = 'profile'
        const fileP = await HandleUpload(selectedFile, `${BASE_URL}/upload`, userId, prefix);

        if (selectedFile) {
            try {
                postUserAva(fileP)
                navigate(`/my`);
            } catch (error) {
                console.log('No file', error);
            }
        }
    }

    return (
        <div className='page-container'>
            <div className="add-photo">
                <h2>Add a photo to {user.first_name}'s page.</h2>
                <UploadFile selectedFile={selectedFile} onChangeFile={handleOnChangeFile} />
                <button onClick={handleSubmit}>Save the photo</button>
            </div>
        </div>
    )
}

export default AddUserPhoto


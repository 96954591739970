
import "./sellingTab.scss";
import { CatContext } from '../../../../context/CatContext';
import { useContext, useEffect, useState } from "react";
import { useTranslations } from "../../../../hooks/useTranslations";
import { ApiUpdateIsActive } from "../../../../api";
import { SellingEdit } from "./SellingEdit";
// import formatDateFromDB from "../../../utils/formatDateFromDB";
// import { LanguageContext } from "../../../context/langContext";
// import { useParams } from "react-router-dom";
import { SaleInformationOneCatContext, SaleInformationOneCatProvider } from "../../../../context/SaleInformationOneCatContext";
import { useParams } from "react-router-dom";

const SellingTab = () => {

    // const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts)
    // const catId = urlParts[urlParts.length - 2];
    const { id: catId } = useParams(); // Grabs catId from the URL
    // console.log("SellingTab | catId", catId)
    return (
        <SaleInformationOneCatProvider catId={catId}>
            <SellingTabChild />
        </SaleInformationOneCatProvider>
    );
}

const SellingTabChild = () => {

    const { translations, language } = useTranslations();
    const { data } = useContext(CatContext);
    const sellData = useContext(SaleInformationOneCatContext);
    // console.log("SellingEdit | SaleInformationOneCatContext | sellData = ", sellData)
    const [isEditing, setIsEditing] = useState(false);
    const [noSellingInfoMessage, setNoSellingInfoMessage] = useState(false);
    // console.log("SellingEditChild | data = ", data)
    //ready_to_move_at: 23 Aug 2023,  ready_to_move_at_input: "2024-08-23
    // console.log("SellingEdit | SaleInformationOneCatContext | sellData.data.data[0] = ", sellData?.data?.data[0])
    // const sellingInformation = sellData?.data
    const [sellingInfoState, setSellingInfoState] = useState({});

    // const [visible, setVisible] = useState(false);
    // const [sellingInformation, setSellingInformation] = useState([])
    // const [isEditing, setIsEditing] = useState(false);
    // const [updatedData, setUpdatedData] = useState(sellingInformation);
    // const [updatedSellingInformation, setUpdatedSellingInformation] = useState({
    //     cat_id: cat.id,
    //     is_active: 0
    // })
    // const [updatedSellingInformationPutUpSale, setupdatedSellingInformationPutUpSale] = useState({
    //     cat_id: cat.id,
    //     is_active: 1
    // })
    // const [active, setActive] = useState(sellingInformation?.is_active === 1)
    // console.log('SellingTab | cat =', data)
    const cat = data

    useEffect(() => {
        if (sellData?.data) {
          setSellingInfoState(sellData.data); // sale info is present
          setNoSellingInfoMessage('');

        } else if (!sellData.loading && !sellData.data) {
          setSellingInfoState(null); // explicitly reset state when no data
          setNoSellingInfoMessage('No selling info');
        }
      }, [sellData]);

    const handleEdit = () => {
        setIsEditing(true)
        setSellingInfoState((prev) => ({
            ...prev,
            isEditing: true,
        }));
    }

    const handleCancel = () => {
        setIsEditing(false);
        setSellingInfoState((prev) => ({
            ...prev,
            isEditing: false,
        }));
    };

    const handleSave = (newData) => {
        // console.log('handleSave | newData:', newData)
        // setSellingInfoState(newData);  // Update the data with the new values
        setSellingInfoState({
            ...newData,
            isEditing: false, // Stop editing after save
        });
        setIsEditing(false);      // Close the editing view
    };

    const handleRemoveAd = async () => {
        // console.log('updatedSellingInformation = ', updatedSellingInformation)
        const updatedRes = await ApiUpdateIsActive({ cat_id: cat.id, is_active: 0 })
        // console.log('handleRemoveAd | updatedRes = ', updatedRes);
        if (updatedRes.success) {
            // Optionally update the UI to reflect that the ad has been removed
            // setActive(false);
            setSellingInfoState((prev) => ({
                ...prev,
                is_active: 0,
            }));
        } else {
            console.log('Error updating Remove is_active status:', updatedRes.message);
            // Handle error message in the UI if needed
        }
    }

    const handlePutUpSale = async () => {
        // console.log('updatedSellingInformation = ', updatedSellingInformation)
        const updatedRes = await ApiUpdateIsActive({ cat_id: cat.id, is_active: 1 })
        // console.log('handlePutUpSale | updatedRes = ', updatedRes);
        if (updatedRes.success) {
            // Optionally update the UI to reflect that the ad has been removed
            // setActive(true);
            setSellingInfoState((prev) => ({
                ...prev,
                is_active: 1,
            }));
        } else {
            console.log('Error updating ad status:', updatedRes.message);
            // Handle error message in the UI if needed
        }
    }

    // const handleNewSaleInformation = async () => {
    //     setIsEditing(true);
    // }

    const SellingView = () => {
        return (
            <div className='selling-tab'>
                <div className="selling-tab__heading">
                    <div className='selling-tab__h3'>
                        <h3>{translations.cat_profile.selling} </h3>
                    </div>
                    <div>
                        {sellingInfoState.is_active ? (
                            <>
                                <span className="selling-tab__active">{translations.cat_profile.active}</span>
                                <button className="button-light-main"
                                    onClick={handleRemoveAd}>
                                    {translations.cat_profile.remove_an_ad}
                                </button>
                            </>
                        ) : (
                            <>
                                <span className="selling-tab__not_active">{translations.cat_profile.not_active}</span>
                                <button className="button-light-main"
                                    onClick={handlePutUpSale}>
                                    {translations.cat_profile.place_an_ad_for_sale}
                                </button>
                            </>
                        )}
                    </div>
                    <button className="button-light"
                        onClick={handleEdit}>
                        {translations.edit}
                    </button>
                </div>

                <section className="selling-info">
                    <div className="">
                        {translations.country}: {language === 'en' ? cat?.country_en : cat?.country_ru}
                    </div>

                    <h4>{translations.price}</h4>

                    <div className="selling__text">
                        <span className="selling__text_label">
                            {translations.cat_profile.price_pet}
                        </span>
                        <span>{sellingInfoState?.price_pet}</span>
                        <span>{sellingInfoState?.currency_abb}</span>
                    </div>

                    <div className="selling__text">
                        <span className="selling__text_label">
                            {translations.cat_profile.price_breeding}</span>
                        <span> {sellingInfoState?.price_breeding}</span>
                        <span>{sellingInfoState?.currency_abb}</span>
                    </div>

                    <div className="selling__text">
                        <span className="selling__text_label">
                            {translations.cat_profile.sale_for_breeding}
                        </span>
                        <span>{sellingInfoState?.sale_for_breeding === 1 ? ' Yes' : ' No'}</span>
                    </div>


                    <h4>{translations.delivery}</h4>

                    <div className="selling__text">
                        <span className="selling__text_label">
                            {translations.cat_profile.ready_date}
                        </span>
                        <span>{sellingInfoState?.ready_to_move_at}</span>
                    </div>

                    <div className="selling__text">
                        <span className="selling__text_label">
                            {translations.cat_profile.delivery}
                        </span>
                        <span>{sellingInfoState?.delivery_text}</span>
                    </div>

                </section>
            </div >
        )
    }

    // console.log('sellingInfoState.isEditing: ', sellingInfoState.isEditing)
    //sellingInfoState.isEditing - prev version
    if (isEditing) {
        return (
            <div className='selling-tab'>
                <div className="selling-tab__heading">
                    <div className='selling-tab__h3'>
                        <h3>{translations.cat_profile.selling} </h3>
                    </div>
                </div>
                <SellingEdit
                    initialData={sellingInfoState}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />

                <button className="button-light-main cancel-button" onClick={handleCancel}>
                    {translations.cancel}
                </button>
            </div>
        );

        //  ------ Checking if sellingInfoState has any keys (indicating it is not empty) ------ //
    } else if (sellingInfoState) {
        return <SellingView />;
      }

    // ------ No selling information - show the button ------ //
    else {
        return (
            <div className='selling-tab'>
                <div className='selling-tab__h3'>
                    {/* <div>
                        {active === true ? translations.cat_profile.active : ''}
                    </div> */}
                    <h3>{translations.cat_profile.selling} </h3>
                    <p>{noSellingInfoMessage}</p>
                </div>
                <button className="button2"
                    onClick={handleEdit}>
                    {translations.cat_profile.put_it_up_for_sale}
                </button>

                {/* {isEditing ? <SellingEdit
                    initialData={sellingInfoState}
                    onSave={handleSave}
                    onCancel={handleCancel}
                /> : <></>} */}
                {/* {visible ? <SellingEdit
                    sellingInformation={sellingInformation}
                /> : <></>} */}

            </div>
        )
    }
}
export default SellingTab





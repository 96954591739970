import { useEffect, useState } from 'react';
import { CatNotRegistered } from './CatNotRegistered';
import { useAuth } from '../../context/authContext.js';
import { fetchApplicationsNotReg } from '../../api';

//============
import "./admin.scss"

const Admin = () => {

    const [catsNotRegistered, setCatsNotRegistered] = useState([]); // From application table then from cats
    const [noApplication, setNoApplications] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const auth = useAuth();
    const currentUserId = auth.currentUser.id;
    // const isAuth = auth.isAuthenticated;
    // const userRole = auth.userRole

    useEffect(() => {
        const getNotRegistered = async () => {
            setIsLoading(true);
            try {

                const resData = await fetchApplicationsNotReg(currentUserId) // From application table then from cats
                if (resData) {
                    // console.log('resData', resData)
                    setNoApplications(false)
                    setCatsNotRegistered(resData);
                } else setNoApplications(true)
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
        getNotRegistered()
    }, [currentUserId])

    const handleDeleteRegistered = (catId) => {
        const catsNew = catsNotRegistered.filter(c => c.id !== catId);
        setCatsNotRegistered({ catsNew })
    }
    // const { isAuthenticated } = useContext(AuthContextProvider);

    return (
        <div className='admin'>
            <h1>Not registered cats</h1>
            <p>Here we see all requests to register a cat (a cat must have a breeder, pedigree and wasn't registered)</p>
            <span>Нужно проверить все данные из родословной с данными на Cat Profile. </span>
            <span> Для этого нужно добавить кота к себе на страницу.</span>
            <span>Нужно добавить родителей и предков и родословной.</span>
            <span>Только после этого можно Подтвердить регистрацию.</span>
            <div className="block__row">

                {isLoading ? (
                    <h2>Loading ...</h2>
                ) : noApplication ? (
                    <h2>No Applications</h2>
                ) : (
                    catsNotRegistered && catsNotRegistered.map((cat) => (
                        <div className="block__column" key={cat.application_id}>
                            <CatNotRegistered
                                cat_id={cat.cat_id}
                                cat_name={cat.cat_name}
                                sex={cat.sex}
                                cat_birthdate={cat.cat_birthdate}
                                application_id={cat.application_id}
                                pedigree_img_url={cat.pedigree_img_url}
                                num={cat.num}
                                issued_by={cat.issued_by}
                                breed={cat.breed}
                                breeder_first_name={cat.breeder_first_name}
                                breeder_last_name={cat.breeder_last_name}
                                breeder_not_user={cat.breeder_not_user}
                                cat_systems_id={cat.cat_systems_id}
                                pedigree_system_abb={cat.pedigree_system_abb}
                                onDelete={handleDeleteRegistered}
                            // onClickAdmin={onClickAdmin}
                            // onCloseDialogAdmin={onCloseDialogAdmin}
                            // dialogOpenAdmin={dialogOpenAdmin}
                            />
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default Admin

import React, { useContext } from 'react'
import "./catprofile.scss";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CatProfileLow from './CatProfileLow/CatProfileLow.jsx';
import {
    deleteCat,
    postCatChangeOwnerFromPrev,
    putUserFromPrev
} from '../../api';
import CatProfileUp from '../CatProfile/CatProfileUp/CatProfileUp.jsx';
import { CatContext, CatContextProvider } from '../../context/CatContext.js';
import { useFetchCatData } from '../../hooks/useFetchCatData.jsx';
import { debug } from '../../services/logger.js';
import { useAuth } from '../../context/authContext.js';

const CatProfile = ({ publicC }) => {
    const { id: catIdFromParams } = useParams();
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;

    // console.log("🔍 CatProfile | useParams() catId:", catIdFromParams);

    // Prevent incorrect rendering
    if (!catIdFromParams || catIdFromParams === "registration") {
        console.warn("⚠ CatProfile | Invalid catId, skipping render:", catIdFromParams);
        return null;
    }

    return (
        <CatContextProvider catId={catIdFromParams} key={catIdFromParams} currentUserId={currentUserId}>
            <CatProfileChild isPublicCatProfile={publicC} catId={catIdFromParams} currentUserId={currentUserId} />
        </CatContextProvider>
    );
}

const CatProfileChild = ({ isPublicCatProfile }) => {
    const { data: cat, loading: catLoading } = useContext(CatContext);
    const navigate = useNavigate();
    const editMode = cat?.editMode;
    // let edit;
    // if (editMode === 'edit_public_locked') {
    //     edit = 
    // }
    // console.log('catProfile.jsx | CatProfileChild | cat.editMode:', cat?.editMode)
    // Ensure we have catId from context
    const catId = cat?.id;

    debug('CatProfile.js | CatProfileChild | isPublicCatProfile', isPublicCatProfile); // true or undefined

    // Fetch additional data only when catId is available
    const { state, error403, loading: dataLoading } = useFetchCatData(catId);
    // console.log('state = ', state)
    debug('CatProfile.js | CatProfileChild | state', state)

    if (catLoading || dataLoading) return <p>Loading cat data...</p>;
    if (!catId) return <p>No cat data available.</p>;

    debug('CatProfileChild | cat', cat)
    debug('CatProfileChild | cat', cat)

    // useEffect(() => {
    //     if (!catId || !currentUserId) return; // Exit early if catId or currentUserId is not available

    //     const fetchCatRelatedData = async () => {
    //         // console.log('fetchCatRelatedData | cat: ', cat)

    //         try {
    //             const docs = await fetchDocs(catId, currentUserId) //pedigree and metrics in one object
    //             // console.log('fetchCatRelatedData | docs: ', docs)
    //             if (docs?.status === 403) {
    //                 console.log('docs?.data.message: ', docs?.data.message)
    //                 setError403(docs?.data.message);
    //                 // return
    //             }
    //             const resDataApplication = await fetchApplications(catId); // data comes clean as an object with data (not the whole response)
    //             // console.log('fetchCatRelatedData | resDataApplication: ', resDataApplication) //expected res: { status: 'solve', id: 44}

    //             //fetch previous owner (the last one!, because we have date in created_at entity) from DB Table cat_change_owner
    //             const resDataPrevOwner = await fetchPrevOwnerFromCatChangeOwner(catId); //SELECT prev_owner_id FROM cat_change_owner 
    //             const updatedState = {
    //                 cat: cat || {},
    //                 metric: docs.metric,
    //                 pedigree: docs.pedigree,
    //                 applicationStatus: resDataApplication || {},
    //                 prevOwner: resDataPrevOwner || [], //[{prev_owner_id}] - we are sending to this
    //                 // birthdate: formatDate(cat.cat_birthdate),
    //             };

    //             // if (resDataApplication) updatedState.applicationStatus = resDataApplication;

    //             setState(prevState => ({ ...prevState, ...updatedState }));

    //         } catch (error) { console.log(error) }
    //     }
    //     fetchCatRelatedData();
    // }, [catId, currentUserId])

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    const handleDelete = async () => {
        console.log('delete clicked')
        try {
            await deleteCat(catId)
            navigate('/my')
            // window.location.reload() //to force the document to be fetced from the web server again.
            // Without it, when we delete a cat - it deletes in DB, but still in frontend.In future it better to do it using REDUX or some other managment tools
        } catch (ex) {
            // Expected (404: not found, 400: bad request) - CLIENT ERRORS
            // - Display a specific error message
            if (ex.response && ex.response.status === 404)
                alert('Expected error occured'); // we don't need to log (console.log) errors which come from client
        }
    }

    // Moving cat from my page to prevOwner page
    const handleMove = async () => {
        try {
            const dataToSend = { catId: catId, prevOwner: state.prevOwner[0]?.prev_owner_id }
            // console.log('dataToSendForPut', dataToSend)
            const [response1, response2] = await Promise.all([
                //insert in CAT table a prevOwner 
                putUserFromPrev(dataToSend),
                postCatChangeOwnerFromPrev(dataToSend) //transaction and prevOwner (which was fetched before) in a DB Table cat_change_owner
            ]);

            console.log(response1.data, response2.data);
            navigate('/my')
        } catch (error) {
            console.error('Failed to move cat:', error);
        }
    }

    const catProfileValue = {
        ...state,
        editMode,
        error403,
        handleDelete,
        handleMove
    }

    // console.log('CatProfile.jsx | catProfileValue = ', catProfileValue)

    return (
        // <CatProfileContext.Provider value={catProfileValue}>
        <div className="cat-profile__container" >
            <CatProfileUp catProfileValue={catProfileValue} />
            <CatProfileLow catProfileValue={catProfileValue} />
        </div>
        // </CatProfileContext.Provider>
    )
}

export default CatProfile

//key={catId} urge components rerender
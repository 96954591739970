import React, { createContext, useEffect, useMemo, useState } from 'react';
import { fetchSellingInformationById } from '../api';
import formatDate from '../utils/formatDateFromUTC';
import formatDateTime from '../utils/formatDateTime';
import formatDateFromDB from '../utils/formatDateFromDB';

const SaleInformationOneCatContext = createContext();

function SaleInformationOneCatProvider({ children, catId }) {
  // console.log("SaleInformationOneCatProvider | catId", catId)
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const getCatIdFromCurrentUrl = () => {
  //   const urlParts = window.location.pathname.split('/');
  //   console.log('urlParts = ', urlParts)
  //   const id = urlParts[urlParts.length - 1];
  //   // Check if id is a valid number, if not return null
  //   return isNaN(id) ? null : id;
  // };

  // const catId = useMemo(() => getCatIdFromCurrentUrl(), []);
  // console.log('SaleInformationOneCatProvider | catId', catId)
  useEffect(() => {
    const fetchSaleData = async () => {
      setLoading(true);
      setError(null);

      try {
        const saleData = await fetchSellingInformationById(catId); // return  {} or null
        // console.log("saleDate = ", saleData)
        const { success, data, message } = saleData;

        // console.log('saleData', saleData) // { status: "success", data: { id: 2, ...}}
        if (success && data) {
          // Format fields before setting
          // const newReadyDate = formatDate(saleData.data.ready_to_move_at)
          // saleData.data.ready_to_move_at = newReadyDate;
          // const updatedNewDate = formatDateTime(saleData.data.updated_at);
          // saleData.data.updated_at = updatedNewDate;
          // console.log('SaleInformationOneCatContext | data = ', data);
          // const formattedMoveDateInput = formatDateFromDB(saleData.data.ready_to_move_at);
          // saleData.data.ready_to_move_at_input = formattedMoveDateInput;

          const formattedData = {
            ...data,
            ready_to_move_at: formatDate(data.ready_to_move_at),
            updated_at: formatDateTime(data.updated_at),
            ...(data.ready_to_move_at && {ready_to_move_at_input: formatDateFromDB(data.ready_to_move_at)}),
          };

          setData(formattedData);

          // setActive(sellingData.is_active === 1);
        } else if (!data) {
          setData(null); // explicitly set to null
          console.warn(`No sale data for cat ID ${catId}: ${message}`);
        } else {
          setError(new Error(message || 'Unknown error'));
        }
      } catch (err) {
        console.error('Error fetching sale data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (catId) {
      fetchSaleData();
    }
  }, [catId]);

  // const contextValue = { data, setData, loading, error };
  const contextValue = useMemo(() => ({ data, setData, loading, error }), [data, loading, error]); //not sure 2025


  return (
    <SaleInformationOneCatContext.Provider value={contextValue}>
      {children}
    </SaleInformationOneCatContext.Provider>
  );
}

export { SaleInformationOneCatContext, SaleInformationOneCatProvider };

// Improvements Explained:
// Error Handling:
// Added error state to capture and provide feedback in case the data fetching fails.
// The try-catch block within the fetchSaleData function allows the component to gracefully handle errors.
// Loading State:
// Added a loading state to indicate when the data is being fetched. This is useful for showing loading indicators in the UI.
// Memoization:
// Wrapped the getCatIdFromCurrentUrl call in useMemo to avoid recalculating the cat ID unnecessarily. This helps with performance, especially if the URL path changes frequently or the component re-renders often.
// Similarly, useMemo is used to memoize the context value, ensuring that the context provider only updates its value when the relevant state variables (data, loading, error) change.
// Conditionally Fetching Data:
// The if (catId) check ensures that the fetch operation only occurs when a valid cat ID is present, preventing unnecessary API calls.
// Cleaner Code Organization:
// Simplified the getCatIdFromCurrentUrl function by directly returning the result, making it more readable.
// Consolidated the context value into a single contextValue object, which is cleaner and easier to manage.
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Select from 'react-select';
//MY MODULES//
import "./addCatWithColor.scss"
import { useAuth } from '../../context/authContext.js';
// import SearchCat from '../../components/SearchCat/SearchCat.jsx';
import { formatValuesForSelect } from '../../utils/formatValuesForSelect.js';
import FormInput from '../../components/FormInput/FormInput.jsx';
import fetchCatColorForSelect from '../../services/fetchCatColorForSelect.js';
import { fetchBreeds, postCat, postColor } from '../../api';
import { Clue } from '../../components/ui/Clue/Clue.jsx';


const AddCatWithColor = () => {

  const auth = useAuth() //auth.currentUser from /context/authContext.js
  const [catColors, setCatColors] = useState({});
  const [newColor, setNewColor] = useState({}) // selected color which will be send to server
  const [isWhite, setIsWhite] = useState(true) // selected color which will be send to server
  const [breedOptions, setBreedOptions] = useState([]);
  const navigate = useNavigate()
  // const [infoText, setInfoText] = useState('') // selected color which will be send to server
  // const [openColor, setOpenColor] = useState(false) // selected color which will be send to server

  const [values, setValues] = useState({
    cat_name_cattery_prefix: "",
    cat_name: "",
    sex: "male",
    cat_birthdate: "",
    breed_id: "",
    // pedigree_num: "",
    // breedsSelect: {},
    selectedBreed: { value: "", label: "Select a breed" },  // Ensure this is not an empty object,
    selectedColorMain: { value: "", label: "Select a color" },
    selectedColorGold: { value: "", label: "Select a color" },
    selectedColorTabby: { value: "", label: "Select a tabby color" },
    selectedColorPoint: { value: "", label: "Select a point color" },
    selectedColorWhite: { value: "", label: "Select a white color" },
    selectedColorEye: { value: "", label: "Select an eye color" },
    users_id: auth.currentUser.id,
    is_my_cat: 0
  });

  // const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  // const onChangeMainColor = (selectedOption) => {
  //   setNewColor((prev) => ({ ...prev, main_ems: selectedOption.value }))
  //   if (selectedOption.value !== "w") {
  //     // console.log('selectedOption.value', selectedOption.value);
  //     setIsWhite(false);
  //   } else { setIsWhite(true) }
  //   // console.log("color selectedOption.value", selectedOption.value)
  // };
  // const onChangeGoldColor = (selectedOption) => {
  //   setNewColor((prev) => ({ ...prev, gold_ems: selectedOption.value }))
  //   // console.log("color selectedOption.value", selectedOption.value)
  // };
  // const onChangeWhite = (selectedOption) => {
  //   setNewColor((prev) => ({ ...prev, white_ems: selectedOption.value }))
  //   // console.log("color selectedOption.value", selectedOption.value)
  // };
  // const onChangeTabby = (selectedOption) => {
  //   setNewColor((prev) => ({ ...prev, tabby_ems: selectedOption.value }))
  //   // console.log("color selectedOption.value", selectedOption.value)
  // };
  // const onChangePoint = (selectedOption) => {
  //   setNewColor((prev) => ({ ...prev, point_ems: selectedOption.value }))
  //   // console.log("color selectedOption.value", selectedOption.value)
  // };
  // const onChangeEye = (selectedOption) => {
  //   setNewColor((prev) => ({ ...prev, eye_ems: selectedOption.value }))
  //   // console.log("color selectedOption.value", selectedOption.value)
  // };

  // const onChange = (e) => {
  //     setCat((prev) => ({ ...prev, [e.target.name]: e.target.value, users_id: auth.currentUser.id }))
  // };
  //Get an objject with all colors for using it in Select inputs.

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [colors, breeds] = await Promise.all([fetchCatColorForSelect(), fetchBreeds()]);
        setCatColors(colors);
        // console.log('AddCatWithColors | colors = ', colors)
        setBreedOptions(formatValuesForSelect(breeds, 'breed', 'ems'));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  // if the color is white, we do not show more inputs for colors
  const handleColorChange = (colorType, selectedOption) => {
    // console.log('color selectedOption = ', selectedOption)
    if (colorType === 'main_ems') {
      setValues((prev) => ({ ...prev, selectedColorMain: selectedOption }));
    }
    if (colorType === 'gold_ems') {
      setValues((prev) => ({ ...prev, selectedColorGold: selectedOption }));
    }
    if (colorType === 'tabby_ems') {
      setValues((prev) => ({ ...prev, selectedColorTabby: selectedOption }));
    }
    if (colorType === 'white_ems') {
      setValues((prev) => ({ ...prev, selectedColorWhite: selectedOption }));
    }
    if (colorType === 'point_ems') {
      setValues((prev) => ({ ...prev, selectedColorPoint: selectedOption }));
    }
    if (colorType === 'eye_ems') {
      setValues((prev) => ({ ...prev, selectedColorEye: selectedOption }));
    }
    setNewColor((prev) => ({ ...prev, [colorType]: selectedOption.value }));
    if (colorType === 'main_ems' && selectedOption.value !== 'w') {
      setIsWhite(false);
    } else if (colorType === 'main_ems') {
      setIsWhite(true);
    }
  };

  // console.log('newColor = ', newColor) // {main_ems: 'b', gold_ems: 's'}

  const onChangeBreed = (selectedOption) => {
    // console.log('selectedOption = ', selectedOption)
    setValues((prev) => ({ ...prev, selectedBreed: selectedOption, breed_id: selectedOption.value }))
  }

  const inputs = [
    {
      id: "cat_name_cattery_prefix",
      name: "cat_name_cattery_prefix",
      type: "text",
      label: "Cattery prefix of a cat name",
      placeholder: "Cattery prefix",
      errorMessage: "Use 3-100 letters or numbers",
      pattern: `^[А-Яа-яA-Za-z0-9 ']{3,100}$`,
      required: false,
      maxLength: 100, // limit to 100 characters
      additional: "Allow numbers, letters (3-100 symbols), space and ' ",
      onChange: handleInputChange,
    },
    {
      id: "cat_name",
      name: "cat_name",
      type: "text",
      label: "Cat name (without cattery prefix)*",
      placeholder: "Cat name",
      errorMessage: "Allow numbers, letters (3-100 symbols), space and ' ",
      pattern: "^[А-Яа-яA-Za-z0-9 ']{3,100}$",
      required: true,
      maxLength: 100, // limit to 100 characters
      additional: "Allow numbers, letters (3-100 symbols), space and ' ",
      onChange: handleInputChange,
    },
    {
      id: "cat_name_suffix",
      name: "cat_name_suffix",
      type: "text",
      label: "Cattery suffix",
      placeholder: "Cattery suffix",
      errorMessage: "Allow numbers, letters (3-100 symbols), space and ' ",
      pattern: "^[А-Яа-яA-Za-z0-9 ']{3,100}$",
      required: false,
      maxLength: 100, // limit to 100 characters
      additional: "Allow numbers, letters (3-100 symbols), space and ' ",
      onChange: handleInputChange,
    },
    {
      id: "selectedBreed",
      name: "selectedBreed",
      type: "select",
      label: "Breed*",
      placeholder: "Breed",
      errorMessage: "",
      required: true,
      options: breedOptions,
      // value: values.selectedBreed,
      onChange: onChangeBreed,
    },
    {
      id: 5,
      name: "sex",
      type: "radio",
      label: "Gender*",
      // placeholder: "Gender",
      // errorMessage: "",
      // pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
      onChange: handleInputChange,
    },
    {
      id: 6,
      name: "cat_birthdate",
      type: "date",
      label: "Cat birthday",
      placeholder: "Date",
      errorMessage: "",
      required: false, //without birthdate I can't send date to mysql - error.
      // maxLength: 100 // limit to 100 characters
      onChange: handleInputChange,
    },
    // {
    //   id: 7,
    //   name: "pedigree_num",
    //   type: "text",
    //   label: "Pedigree number",
    //   placeholder: "Pedigree number",
    //   errorMessage: "",
    //   required: false, //without birthdate I can't send date to mysql - error.
    //   // maxLength: 100 // limit to 100 characters
    //   // className:"joinInput"
    // },
  ]

  const inputsMainColor = [
    {
      id: "selectedColorMain",
      name: "selectedColorMain",
      type: "select",
      label: "Main Color*",
      options: catColors?.arrayMainColor,
      required: true,
      onChange: (selectedOption) => handleColorChange("main_ems", selectedOption),
    }
  ]

  const inputsColor = [
    {
      id: "gold",
      name: "selectedColorGold",
      type: "select",
      label: "Gold or Silver",
      options: catColors?.arrayGoldColor,
      // value: ,
      required: false,
      onChange: (selectedOption) => handleColorChange("gold_ems", selectedOption),
    },
    {
      id: "white",
      name: "selectedColorWhite",
      type: "select",
      label: "White spots",
      options: catColors?.arrayWhiteColor,
      required: false,
      onChange: (selectedOption) => handleColorChange("white_ems", selectedOption),
    },
    {
      id: "tabby",
      name: "selectedColorTabby",
      type: "select",
      label: "Tabby",
      options: catColors?.arrayTabbyColor,
      required: false,
      onChange: (selectedOption) => handleColorChange("tabby_ems", selectedOption),
    },
    {
      id: "point",
      name: "selectedColorPoint",
      type: "select",
      label: "Point",
      options: catColors?.arrayPointColor,
      required: false,
      onChange: (selectedOption) => handleColorChange("point_ems", selectedOption),
    },
    {
      id: "eye_color",
      name: "selectedColorEye",
      type: "select",
      label: "Eye color*",
      options: catColors?.arrayEyeColor,
      required: true,
      onChange: (selectedOption) => handleColorChange("eye_ems", selectedOption),
    },
  ]

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('values before post', values)
    // console.log("color before sinding", newColor)
    try {
      const res = await postCat(values)
      const catInsertedId = res.data.newCatId
      // console.log('newColor', newColor) //UNDEFINED!!!
      // console.log('res.data.insertId', catInsertedId) //UNDEFINED!!!
      if (catInsertedId) {
        try {
          const resPostColor = await postColor(catInsertedId, newColor)
          // console.log('resPost color', resPostColor)
          // setInfoText('Cat was added to database. Now you can mark it as father or mother')
        } catch (error) { console.log(error) }
      }
      navigate(`/my`)
    } catch (error) { console.log(error) }
  }

  const cancelEdit = () => {
    navigate(`/my`)
  }

  // const onClickMakeAsMother = (e) => {
  //   e.preventDefault();
  //   try {
  //     const res = await http.post(config.mother, );
  //     const catInsertedId = res.data.insertId
  //     console.log('res.data.insertId', catInsertedId)
  //     if (res.data.insertId) {
  //       try {
  //         const res = await http.post(config.color, { cat_id: catInsertedId, ...newColor })
  //         console.log('res post color', res)
  //         setInfoText('Cat was added to database. Now you can mark it as father or mother')
  //       } catch (error) { console.log(error) }
  //     }
  //     // navigate(`/cats/${values.id}`)
  //   } catch (error) { console.log(error) }

  // }

  const ColorComponent = () => {
    return (

      <>
        <div>
          Cat color chosen:
          <h2>{newColor?.main_ems} {newColor?.gold_ems} {newColor?.white_ems} {newColor?.tabby_ems} {newColor?.point_ems} {newColor?.delute_ems} {newColor?.eye_ems}
          </h2>
        </div>
        {
          inputsMainColor.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name] || ''} />
          ))
        }
        {
          inputsColor.map((input) => (!isWhite &&
            (<FormInput
              key={input.id}
              {...input}
              value={values[input.name] || ''}
            />)))
        }
      </>
    )
  }
  const handleCheckboxChange = (event) => {
    setValues((prev) => ({ ...prev, is_my_cat: event.target.checked ? 1 : 0 }))
  }

  return (
    <div className="add-cat-container">
      <div className="add-cat-content">
        <form
          onSubmit={handleSubmit}>
          <h1>Add a cat with color</h1>
          <p>If you didn't find a cat in DB, you can add a new one here.</p>
          {/* <small><em>
            When adding a new cat to the CatClub website, avoid selecting "it is my cat" unless the cat is personally owned by you. For instance,
            if you're including multiple cats as ancestors in your cat's pedigree, this option should not be marked.
          </em></small> */}
          {inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name] || ''}
              aria-label={input.label}
            />
          ))}
          {/* <button type='button' className='button-light' onClick={() => setOpenColor(true)}>Add cat and add color</button>           */}
          <ColorComponent />
          <input
            type='checkbox'
            onChange={handleCheckboxChange}
          />
          <label>It's my cat - check if you are the owner</label>

          <Clue text={
            "When adding a new cat to the CatClub website, avoid selecting 'it is my cat' unless the cat is personally owned by you. For instance,if you are including multiple cats as ancestors in your cat's pedigree, this option should not be marked."
          } />
          <button className='joinButton'>Send</button>
          {/* <p>{infoText}</p> */}
          {/* {infoText.length > 2 && (
            <React.Fragment>
              <button type='button' className='button-light' onClick={onClickMakeAsMother}>Mark as mother</button>
              <button type='button' className='button-light' onClick={onClickMakeAsFather}>Mark as father</button>
            </React.Fragment>
          )} */}

          <button type='button' className='button-light' onClick={cancelEdit}>Cancel</button>
        </form>
      </div>
    </div>
  )
}

export default AddCatWithColor

import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/langContext";
import { useAuth } from "../../context/authContext";
import "./dropdownMenu.scss";


export const DropdownMenu = (
  { cat, which, currentUserId, linkUpdate, linkAddPhoto, linkDelete, linkRegistration, linkAddMate, prevOwner, handleMove, handleDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  // console.log('props DropDownMeny = ', handleDelete)
  const { setLanguage } = useContext(LanguageContext);
  const { translations } = useContext(LanguageContext)
  // const { currentUser, logout } = useContext(AuthContextProvider);
  const auth = useAuth();
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    navigate('/');
    auth.logout();
  }

  const renderDropdownContent = () => {
    switch (which) {
      case 'user':
        return (
          <>
            <Link to={`/users/${currentUserId}/addphoto`}>{linkAddPhoto}</Link>
            <Link to="/edit">{linkUpdate}</Link>
            <Link to="/settings">Settings</Link>
          </>
        );

      case 'cat':
        // if (which === 'user') {

        //   return (
        //     <div className="dropdown">
        //       <button onClick={toggleDropdown} className="dropbtn">☰</button>
        //       {isOpen && (
        //         <div className="dropdown-content">
        //           <Link to={`/users/${currentUserId}/addphoto`}>Add a photo</Link>
        //           <Link to={`/edit`}>Update</Link>
        //           <Link to={`/settings`}>Settings</Link>
        //         </div>
        //       )}
        //     </div>
        //   );
        // } else if (which === 'cat') {

        return (
          <>
            <Link className="drop__item" to={`/cats/${cat.id}/addphoto`}>{linkAddPhoto}</Link>
            {cat.registered === 0 ?
              (
                <div className='dpop__button-container'>
                  <Link className="drop__item" to={`/cats/${cat.id}/update`}>{linkUpdate}</Link>
                  <Link className="drop__item" to={`/cats/${cat.id}/registration`}>{linkRegistration}</Link>
                  {prevOwner?.length >= 1 ? (
                    <Link className="drop__item" onClick={handleMove}>
                      <span>Delete it from my page. </span>
                      <span className="dropdown__small">I'm not an owner.  Move to a previous online owner.</span>
                    </Link>
                  ) : (
                    <>
                      {/* <Link className="drop__item" onClick={handleDelete}>{linkDelete}</Link> */}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {cat.sex === 'female' &&
                    // <div
                    //   className='mate-clue'
                    //   data-clue='Registered female cat can add mates and kittens. Register your cat!'>
                    //   {/* <button className="button-light">{translations.add_mate}</button> */}
                    // </div>
                      <Link className="drop__item">{linkAddMate}</Link>
                  }
                  <Link className="drop__item" to={`/cats/${cat.id}/updateshort`}>{linkUpdate}</Link>
                </>
              )}
          </>
          // <div className="dropdown">
          //   <button onClick={toggleDropdown} className="dropbtn">☰</button>
          //   {isOpen && (
          //     <div className="dropdown-content">
          //       <Link className="drop__item" to={`/cats/${cat.id}/addphoto`}>{link1}</Link>
          //       {/* <Link to={`/edit`}>{linkUpdate}</Link> */}
          //       {/* <Link to={`/edit`}>{linkDelete}</Link> */}
          //       {
          //         cat.registered === 0 ? (
          //           <div className='dpop__button-container'>
          //             <Link className="drop__item" to={`/cats/${cat.id}/update`}>Update</Link>
          //             {prevOwner?.length >= 1 ?
          //               (<Link className="drop__item" onClick={handleMove}>
          //                 I'm not an owner. Delete it from my page. Move to a previous online owner.
          //               </Link>
          //               ) : (
          //                 <Link className="drop__item" onClick={handleDelete}>Delete</Link>
          //               )}
          //           </div>
          //         ) : (
          //           <Link className="drop__item" to={`/cats/${cat.id}/updateshort`}>Update</Link>
          //         )
          //       }
          //     </div>
          //     //  We don't show delite and update button when cat is registered !!!
          //   )}
          // </div>
        );

      case 'header':

        return (
          <>
            <Link className="dropdown__link" to="my">
              {currentUser.first_name} - {translations.header?.profile}
            </Link>
            <Link className="dropdown__link" to="add">{translations.header?.add_cat}</Link>
            <div className="language-wrap">
              <Link onClick={() => setLanguage('en')}>English</Link>
              <Link onClick={() => setLanguage('ru')}>Русский</Link>
            </div>
            <Link className="dropdown__link" onClick={handleLogout}>{translations.header?.logout}</Link>
          </>
          // <div className="dropdown">
          //   <button onClick={toggleDropdown} className="dropbtn-header">
          //     {
          //       currentUser && currentUser.first_name
          //         ? currentUser.first_name.charAt(0) // Gets the first character of the first name
          //         : '' // Default fallback if first_name is not available
          //     }
          //   </button>
          //   {isOpen && (
          //     <div className="dropdown-content">
          //       <Link className="dropdown__link" to={`my`}> {currentUser.first_name} - {translations.header?.profile} </Link>
          //       <Link className="dropdown__link" to={`add`}>{translations.header?.add_cat}</Link>
          //       <div className="language-wrap">
          //         <Link onClick={() => setLanguage('en')}>English</Link>
          //         <Link onClick={() => setLanguage('ru')}>Русский</Link>
          //       </div>
          //       <Link className="dropdown__link" onClick={handleLogout}>{translations.header?.logout}</Link>
          //     </div>
          //   )}
          // </div>
        );

      default:
        return null;
    }
  }

  return (
    <div className="dropdown">
      <button onClick={toggleDropdown} className={which === 'header' ? 'dropbtn-header' : 'dropbtn'}>
        {which === 'header' && currentUser ? currentUser.first_name.charAt(0) : '☰'}
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {renderDropdownContent()}
        </div>
      )}
    </div>
  );
};



// {cat.sex === 'female' &&
//   // (
//   //     cat.registered !== 0 ?
//   //         (<div
//   //             className='cat-profile_others__clue'
//   //             data-clue='Registered female cat can add mates and kittens. Register your cat!'>
//   //             <button className="button-light">{translations.add_mate}</button>
//   //         </div>
//   //         ) : (
//   //             <div
//   //                 className='cat-profile_others__clue'
//   //                 data-clue='Registered female cat can add mates and kittens. Register your cat!'>
//   //                 <button className="button-light" disabled>{translations.add_mate}</button>
//   //                 {/* <span><Clue text={'Registered female cat can add mates and kittens. Register your cat!'} /></span> */}
//   //             </div>
//   //         )
//   // )
// // }
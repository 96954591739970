
////// APPLICATIONS

import http from '../services/httpService';
// import { debug, error } from '../services/logger';

export const fetchApplications = async (catId) => {
    const { data } = await http.get(`/cats/${catId}/applications`)
    // console.log('API > applications.js > data = ', data)
    // Return only the first item in the data array (data.data[0])
    // console.log('fetchApplications',data.data) //Expected: {status: 'not_found', application_id: null}
    return data.data;  // Return an empty object if no data exists};
}

export const sendToRegistration = (catId, pedigrees_id) => {
    return http.post(`/applications/send-to-registration/${catId}`, { pedigrees_id })

};

// we do not need to send currentUserId, because backend get it from req.userInfo from mildware
// ADMIN
export const putRegisterPedigree = async (catId) => {
    const result = http.put(`/cats/${catId}/register-pedigree`)
    return result
};

export const deleteApplication = async (catId, currentUserId) => {
    const result = http.put(`/cats/${catId}/delete-pedigree-application`, currentUserId)
    console.log('API > application.js > deleteApplication > result = ', result)
    return result
};
////// CATS //////
import http from '../services/httpService';
import { error } from '../services/logger';

// export const fetchCats = async () => {
//     const { data } = await http.get(`/cats`);
//     return data;
// };

// export const fetchMarektSellingCats = async () => {
//     const { data } = await http.get(`/cats/selling`);
//     // console.log('data from fetchMarektSellingCats ', data)
//     return data;
// };

export const fetchCatsWithColor10Free = async (query, p) => {
    const { data } = await http.get(`/cats/all-with-color-10-free?q=${query}&p=${p}`)
    // console.log('cats.js > fetchCatsWithColor10Free > data = ', data)
    return data.data
};

//fetch parents by Gender
//used by: AddParentSearch.jsx
export const fetchCatsWithColor10ByGender = async (query, sex) => {
    const { data } = await http.get(`/cats/all-with-color-10-by-gender?q=${query}&sex=${sex}`)
    return data.data
};

export const fetchUserIdByCatId = async (catId) => {
    // console.log('api.js > fetchUserIdByCatId > catId = ', catId)
    const res = await http.get(`/cats/user-id-by-cat-id/${catId}`);
    // console.log('fetchUserIdByCatId res = ', res)
    if (res?.status >= 200 && res?.status < 300) {
        return res.data // return { users_id: 10}
    }
    else {
        throw new Error(`fetchUserIdByCatId /cats/user-id-by-cat-id/${catId}, recieved ${res?.status}`)
    }
};

export const fetchCatName = async (catId) => {
    const res = await http.get(`/cats/${catId}/name`);
    if (res.status >= 200 && res.status < 300) {
        return res.data
    }
    else {
        throw new Error(`Could not fetch OneCat /cats/${catId}, recieved ${res.status}`)
    }
};
// export const fetchOneCat = async (catId) => {
//     const res = await http.get(`/cats/${catId}`);
//     if (res.status >= 200 && res.status < 300) {
//         return res.data
//     }
//     else {
//         throw new Error(`Could not fetch OneCat /cats/${catId}, recieved ${res.status}`)
//     }
// };

export const fetchOneCatAndColor = async (catId, currentUserId) => {
    // console.log("currentUserId", currentUserId)
    try {
        const response = await http.get(`/cats/cat-and-color/${catId}`, { params: { currentUserId } });
        // console.log('API.js | fetchOneCatAndColor | response', response);
        const { modifiedData: data, success, message } = response.data;
        if (!success || !data || (Array.isArray(data) && data.length === 0)) {
            return {
                success: true,
                data: null,
                message: message || 'No cat or color data found'
            };
        }

        return {
            success: true,
            data,
            message: message || 'Cat and color data fetched successfully'
        }
        // if (data?.success) {
        //     return data.data; //return only the useful payload, not { data, success, message}
        // } else {
        //     throw new Error(data.message || "Unknown error occurered while fetching cat data")
        // }
        // if (res.status >= 200 && res.status < 300) {
        //     return res.data;
        // } else {
        //     throw new Error(`Could not fetch OneCat /cats/cat-and-color/${catId}, received ${res.status}`);
        // }
    } catch (err) {
        error('api.js | Error in fetchOneCatAndColor:', err);
        console.error('api.js | Error in fetchOneCatAndColor:', err);
        throw err;
    }
};


// export const fetchMyCats = async (currentUserId) => {
//     const res = await http.get(`/cats/mycats?user=${currentUserId}`)
//     if (res.status >= 200 && res.status < 300) {
//         return res.data
//     }
//     else {
//         throw new Error(`Could not fetch OneCat /cats/mycats?user=${currentUserId}, recieved ${res.status}`)
//     }
// };

export const getMyCats = async (isSell) => {
    const params = { isSell }

    // if (typeof isSell !== 'undefined') {
    //     params.isSell = isSell;
    // }

    // console.log("api.js getMyCats params = ", params);

    try {
        const res = await http.get(`/me/cats`, { params });
        // console.log("API.js | getMyCats | HTTP status =", res.status);
        if (res.status >= 200 && res.status < 300) {
            // console.log(" API.js | getMyCats | res.data.data =", res.data.data)
            return res.data.data
        }
        else {
            console.error(`Error: Received status code ${res.status} from /cats`);
            throw new Error(`Could not fetchSellCat usein API /cats , params, recieved ${res.status}`)
        }
    } catch (error) {
        console.error(`Failed to fetch cats for sale: ${error.message}`);
        throw new Error('Failed to fetch sell cats. Please try again later.');
    }

};

// HTTP requests from the client (e.g., a browser) to the server and want to include cookies, you need to configure your requests to include credentials.
export const fetchApplicationsNotReg = async (currentUserId) => {
    try {
        // Step 1: Fetch applications not solved
        const applicationsNotSolvedRes = await http.get(`/applications/not-solve`, {
            params: { userId: currentUserId },
            withCredentials: true
        })
        // console.log('applicationsNotSolvedRes.data.data', applicationsNotSolvedRes.data.data)

        // Step 2: Handle no content
        if (applicationsNotSolvedRes.status === 204) {
            // console.log('We DO NOT have applications!')
            return; // Added return statement to stop execution here
        }

        // Step 3: Extract cat_ids and fetch not registered cats
        const applications = applicationsNotSolvedRes.data?.data;
        if (applications?.length > 0) {
            //distructure answer from db res.status(200).json({ message: "Applications retrieved successfully", catIds: catIds, application: application_id});
            const ids = applications.map(app => app.cat_id);

            // console.log('We have ids = ', ids);
            const notRegisteredCatsRes = await http.get(`/cats/notregistered`, {
                params: { ids },
                withCredentials: true
            })

            if (notRegisteredCatsRes.status >= 200 && notRegisteredCatsRes.status < 300) {
                // console.log('notRegisteredCatsRes.data', notRegisteredCatsRes.data)
                const notRegisteredCats = notRegisteredCatsRes.data; //array with cats objects

                // Combine data into one object per application
                const combined = applications.map(app => {
                    // Find the corresponding cat by matching cat_id
                    const matchingCat = notRegisteredCats.find(c => c.cat_id === app.cat_id);
                    // console.log('matchingCat', matchingCat)
                    // Return a new object that includes all properties of the application
                    // and adds a 'cat' property with the found cat or null if not found
                    const result = {
                        ...app,
                        ...matchingCat
                    };

                    return result;
                });

                // console.log('combined data', combined);
                return combined;
            }

            else {
                throw new Error(`Could not fetch OneCat /cats/notregistered, recieved ${notRegisteredCatsRes.status}`)
            }
        }
    } catch (error) {
        console.error('Error in fetchApplicationsNotReg:', error.message);
        throw error;
    }
};

export const putCat = async (values) => {
    const response = await http.put(`/cats/${values.id}`, values)
    return response
};
// is used in: UpdateShort.jsx edit cat profile (description and chip)
export const patchCat = async (values) => {
    // console.log('API.js > patchCat > values = ', values)
    const response = http.patch(`/cats/${values.id}`, values)
    return response
};

export const putUserFromPrev = async (data) => {
    // console.log('user-from-prev data = ', data)
    const response = await http.put(`/cats/user-from-prev`, data)
    return response
};


export const postCat = async (values) => {
    const res = await http.post(`/cats`, values);
    // console.log('API.js > postCat > res.data = ', res.data)
    return res.data;
};

// export const postParent = (values) => {
//     return http.post(`/cats/parent`, values);
// };

export const deleteCat = async (catId) => {
    // console.log('deleteCat catId =', catId);
    http.delete(`/cats/${catId}`)
        .then(response => {
            // Successfully deleted user
            console.log('api deleteCat response = ', response);
        })
        .catch(error => {
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message);  // Alert the user-friendly error message
            } else {
                alert('An unexpected error occurred.');
            }
        })
};

export const postCatAva = async (catId, fileP) => {
    const response = await http.post(`/cats/${catId}/catavaurl`, fileP);
    return response
};



export const updateOwnerAtCat = async (catId) => {
    const result = http.put(`/cats/update-owner-at-cat/${catId}`)
    return result
};
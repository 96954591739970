import React, { useContext, useEffect, useState } from 'react'
// import { createWorker } from 'tesseract.js';
// import Tesseract from 'tesseract.js';
// 
import './catRegistration.scss'
import { UploadFile } from '../../components/UploadFile/UploadFile.jsx';
import { debug } from "../../services/logger.js";

// import { recognizePed } from '../../services/api';
import { CatContext, CatContextProvider } from '../../context/CatContext.js';
// import { LanguageContext } from '../../context/langContext.js';
import { useParams } from 'react-router-dom';
import { fetchApplications, fetchOnePedigree, sendToRegistration } from '../../api';
// import AddCatFromAI from '../AddCatFromAI/AddCatFromAI.jsx';
// import CreatePedigree from '../CreatePedigree/CreatePedigree';
import { useAuth } from '../../context/authContext.js';
import { LanguageContext } from '../../context/langContext.js';
// import DocumentsTab from '../CatProfile/CatProfileTabs/DocumentsTab';

const CatRegistration = () => {
    // const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts)
    // const catId = urlParts[urlParts.length - 2];
    const { id: catId } = useParams(); // Grabs catId from the URL
    // console.log("EditCatProfile | catId", catId)
    return (
        <CatContextProvider catId={catId}>
            <CatRegistrationChild catId={catId} />
        </CatContextProvider>
    );
}

const CatRegistrationChild = ({ catId }) => {
    const [file1, setFile1] = useState(null)
    const [pedigreeId, setPedigreeId] = useState('')
    const [errorNoPedId, setErrorNoPedId] = useState('')
    const [applicationSent, setApplicationSent] = useState(false)
    // const [checkReg, setCheckReg] = useState(false)
    const [applicationUnderReview, setApplicationUnderReview] = useState(false)
    // const [catNumber, setCatNumber] = useState('');
    const [loading, setLoading] = useState(false);
    // const [regOption, setRegOption] = useState('')
    // const options = ['reg', 'regOwner', 'owner']
    // const [recognizeData, setRecognizedData] = useState([])
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;
    const { translations } = useContext(LanguageContext
    )

    debug('catRegistration.jsx | cat id to register', catId)
    const { data: cat } = useContext(CatContext);
    // console.log('catRegistration.jsx | cat: ', cat)
    // console.log('catRegistration.jsx | catId: ', catId)

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch cat systems (common to both metric and pedigree)
                const resData = await fetchOnePedigree(catId, currentUserId);
                // console.log('catRegistration.jsx | resData', resData)
                if (!resData?.id) {
                    setErrorNoPedId('Please, add pedigree')
                } else {
                    const resApplication = await fetchApplications(catId)
                    // console.log('catRegistration.jsx | resApplication', resApplication)
                    if (resApplication.id) {
                        setApplicationUnderReview(true)
                    }
                    setPedigreeId(resData?.id); // save id
                }
            } catch (err) {
                if (err.response?.status === 404) {
                    setErrorNoPedId('Please, add pedigree');
                } else {
                    console.error('Unexpected error while fetching pedigree:', err);
                    setErrorNoPedId('Unexpected error, please try again');
                }
            }
        }
        fetchData()
    }, [catId, currentUserId])

    //UploadFile return us a file for the futher proccessing
    const handleOnChangeFile1 = (file) => {
        setFile1(file);
    }

    // const handleOnChangeFile2 = (file) => {
    //     setFile2(file);
    // }
    // const pedigreeId = 0;
    // console.log('pedigreeId', pedigreeId)

    const handleSendPedigree = async () => {
        // const toSend = [file1, file2, cat.id]
        debug('CatRegistration.jsx > handleSendDocuments, cat.id ', cat.id)
        // if (!file1) {
        //     alert('Please select a file to upload.');
        //     return;
        // }

        try {
            const result = await sendToRegistration(catId, pedigreeId);
            // console.log('result after sendToRegistration', result)
            if (result.status === 200) {
                setApplicationSent(true);
            }
            // Check if result is already an object, if not, parse it
            // if (typeof result.recognizedText === 'string') {
            //     try {
            //         const parsedResult = JSON.parse(result.recognizedText);
            //         console.log('Parsed JSON:', parsedResult);
            //         setRecognizedText(parsedResult);
            //         return parsedResult;
            //     } catch (err) {
            //         throw new Error('Failed to parse JSON response');
            //     }
            // } else {
            //     return result; // It's already a valid JSON object
            // }
            // console.log('Extracted Pedigree:', result.recognizedText);
            // setRecognizedData(result.recognizeText)

            // Step 1: Remove markdown backticks
            // const cleanedText = result.recognizedText.replace(/^```json/, '').replace(/```$/, '').trim();
            // console.log('cleaned', cleanedText)
            // setRecognizedData(cleanedText)
            // Step 2: Parse the cleaned JSON string
            // try {
            //     const jsonData = JSON.parse(result.recognizedText);
            //     console.log('Parsed JSON:', jsonData);
            //     setRecognizedData(jsonData);
            // } catch (error) {
            //     console.error('Error parsing JSON:', error);
            // }
        } catch (error) {
            // alert('Failed to process the pedigree. Please try again.');
        }
    }

    const ApplicationSentMessage = () => (
        <div className="application-sent-message">
            <h3>🎉 Your application was sent successfully!</h3>
            <p>We will review the documents and notify you shortly.</p>
        </div>
    );

    const Reg = () => {
        return (
            <>
                <div className="add-cat-pedigree-recognition">
                    {/* <label htmlFor="cat_number">Cat number you want to register (if you have added it before)</label>
                    <input type="text" id="cat_number" /> */}
                    <UploadFile file={file1} onChangeFile={handleOnChangeFile1} fileId="file1" />

                    {/* for ownership purchase agreement */}
                    {/* {regOption !== 'reg' && regOption !== 'owner' &&
                        <UploadFile file={file2} onChangeFile={handleOnChangeFile2} fileId="file2" />
                    } */}

                    <button
                        className='primary-button-small'
                        // onClick={handleRecognition}
                        disabled={!file1 || loading}
                        onClick={handleSendPedigree}
                    >
                        {loading ? 'Processing...' : 'Send documents'}
                    </button>
                    {/* {applicationSent ? (
                        <div>Application was sent sucsessfully!</div>
                    ) : (
                        <></>
                    )} */}
                </div>
            </>
        )
    }

    // const handleOptions = (index) => {
    //     // info('CatRegistration > Reg', 'Option is chosen', options[index])
    //     if (regOption === options[index]) {
    //         setRegOption('')
    //     } else {
    //         info('CatRegistration > Reg', `Option is chosen = ${options[index]}`)
    //         setRegOption(options[index])
    //     }
    // }
    // console.log(cat)

    return (
        <>
            <h1>Registration for {cat?.cat_name_cattery_prefix && cat?.cat_name_cattery_prefix} {cat?.cat_name} with id {cat?.id} </h1>
            {/* <p>Choose one option</p>
            <div className="cat-registration">
                <h2>Cat and Ownership Registration - recomended!</h2>
                <span>Cat and Ownership Registration lets you:</span>
                <ul>
                    <li>
                        Full access to all breeding support and documents;
                    </li>
                    <li>
                        Issue kittens documnts including kittens pedigrees;
                    </li>
                    <li>
                        Sell kittens on the marketplace.
                    </li>
                </ul>

                <p>Just upload a cat pedigree and a cat purchase agreement. </p>
                <button className='secondary-button' onClick={() => handleOptions(1)}>Continue</button >
                {regOption === options[1] && <Reg />}
            </div > */}

            <div className="cat-registration">
                <h2>Cat Registration {pedigreeId && `(pedigree id: ${pedigreeId})`}</h2>
                {errorNoPedId ? (
                    <div className='error'>{errorNoPedId}</div>
                ) : (
                    <>
                    </>
                )}
                {/* <input
                    type="checkbox"
                    id="checkReg"
                    checked={checkReg}
                    onChange={() => setCheckReg(!checkReg)} />
                <label htmlFor="checkReg">Cat Registration</label>  */}
                {/* <span>Why do you need to register cat without an ownership?</span> */}
                <h3>For registration you need:</h3>
                <ul>
                    <li>
                        Add a cat with name, breed, color and other information
                    </li>
                    <li>
                        {errorNoPedId ? (
                            <div className='error'>Add pedigree on a Cat Profile (Tab Documents) - Need to be added</div>
                        ) : (
                            <>
                                Add pedigree on a Cat Profile (Tab Documents)
                            </>
                        )}
                    </li>
                    {/* <li>
                        Prevent an online cat profile from deleting - Registered cats can't be deleted or edited
                    </li> */}
                </ul>
                <h3>When male(father) and female cat (mother) are registered, you can:</h3>

                <ul>
                    <li>
                        Issue kittenss documents - they will be registered automaticaly. Do not need to fill any forms.
                    </li>
                    <li>
                        Sell kittens on marketplace
                    </li>
                    <li>
                        Prevent an online cat profile from deleting - Registered cats can't be deleted or edited
                    </li>
                    <i>You want to register someone else cat - it's allowed in catclub!</i>
                </ul>
                {/* <p>Just upload a cat pedigree</p> */}
                {/* <button className='secondary-button' disabled={errorNoPedId} onClick={() => handleOptions(0)}>Continue</button > */}
                {/* <button
                    className='secondary-button'
                    // onClick={handleRecognition}
                    disabled={errorNoPedId}
                    onClick={handleSendPedigree}
                >
                    {loading ? 'Processing...' : 'Send documents to Registration'}
                </button> */}

                {applicationSent ? (
                    <ApplicationSentMessage />
                ) : applicationUnderReview ? (
                    <div>{translations.application_under_review}</div>
                ) : (
                    <div>
                        <p>Just upload a cat pedigree</p>
                        <button
                            className='secondary-button'
                            disabled={errorNoPedId}
                            onClick={handleSendPedigree}
                        >
                            {loading ? 'Processing...' : 'Send documents to Registration'}
                        </button>
                    </div>
                )}
                {/* {regOption === options[0] && (
                    applicationSent ? <ApplicationSentMessage /> : <Reg />
                )} */}

                {/* <div className="add-cat-pedigree-recognition">
                    <label htmlFor="cat_number">
                        Cat number you want to register (if you have added it before)
                    </label>
                    <input type="text" id="cat_number" />
                    <UploadFile file={file1} onChangeFile={handleOnChangeFile1} fileId="file1" />
                    <button
                        className='primary-button-small'
                        // onClick={handleRecognition}
                        disabled={!file1 || loading}
                    >
                        {loading ? 'Processing...' : 'Send Pedigree'}
                    </button>
                    {recognizedText && (
                        <div className="recognized-text">
                            <h3>Extracted Text:</h3>
                            <p>{recognizedText}</p>
                        </div>
                    )}
                </div> */}
            </div>
            {/* <div>
                <p>Recognized Text</p>
                <p>{recognizeData}</p>
            </div>
            <AddCatFromAI aidata={recognizeData} /> */}

            {/* <div className="cat-registration">
                <h2>Ownership Registration (for registered cats)</h2>
                <span>Ownership Registration benefits:</span>
                <ul>
                    <li>Your are the only who own this cat and its online profile;</li>
                    <li>
                        Nobody can become an online owner of your cat;
                    </li>
                    <li>
                        You can issue documents for kittens from the cat.
                    </li>
                    <li>
                        Nobody can see sensitive information about your cat.
                    </li>
                </ul>
                <p>Just upload a cat purchase agreement. </p>
                <button className='secondary-button' onClick={() => handleOptions(2)}>Continue</button >
                {regOption === options[2] && <Reg />}
                {/* {checkRegAndOwner &&
                    <>
                        <div className="add-cat-pedigree-recognition">
                            <label htmlFor="cat_number">Cat number you want to register (if you have added it before)</label>
                            <input type="text" id="cat_number" />
                            <UploadFile file={file2} onChangeFile={handleOnChangeFile2} fileId="file2" />
                            <UploadFile file={file3} onChangeFile={handleOnChangeFile3} fileId="file3" />

                            <button
                                className='primary-button-small'
                                // onClick={handleRecognition}
                                disabled={!file2 || !file3 || loading}
                            >
                                {loading ? 'Processing...' : 'Send Pedigree'}
                            </button>
                        </div>
                    </>
                } */}
            {/* </div> */}
        </>
        // <UploadFile selectedFile={selectedFile} onChangeFile={handleOnChangeFile} />
    )

}

export default CatRegistration

import axios from "axios";
// import logService from "./logService";
// Handling Unexpected Errors Globally. null because we don't need to log a success. We need to log only errors.

axios.defaults.baseURL = process.env.REACT_APP_API_URL; // - main, but the second option is to have const BASE_URL = process.env.REACT_APP_API_URL in api.js
axios.defaults.withCredentials = true;// !!!!! add credential (cookies) to hadder in HTTP request, otherwise will have a 401 error (unauth)

axios.interceptors.response.use(
    response => response,
    error => {

        // Expected client-side errors (e.g. 400 Bad Request, 404 Not Found)
        const expectedError = error.response && error.response.status >= 400 && error.response.status < 500

        if (!expectedError) {
            console.log("Log the error from INTERCEPTOR", error);
            console.error("❗ Unexpected error (INTERCEPTOR):", error);
            // logService.log(error);
            // alert("An unexpected error occured");

            // Optionally show user-facing alert in production:
            if (process.env.NODE_ENV !== "development") {
                alert("Something went wrong. Please try again later.");
            }
        } else {
            // Optional: log client errors for debugging
            if (process.env.NODE_ENV === "development") {
                console.warn("⚠️ Client error:", error.response?.status, error.response?.data);
            }
        }
        return Promise.reject(error); // still propagate the error
    }
);

const http = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete
}

export default http;
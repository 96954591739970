
import http from '../services/httpService';
import { debug } from '../services/logger';

////// PEDIGREE and DOCS
export const fetchDocs = async (catId, currentUserId) => {
    try {
        const { data } = await http.get(`/cats/${catId}/docs`, { params: { currentUserId } });
        debug('API.js | fetchDocs | data: ', data);

        // Check if the data contains a 403 status
        if (data.status === 403) {
            // console.log(' API.js | fetchDocs | data.response.data.message: ', data.response.data.message);
            return data.response.data;
        }

        return data;
    } catch (error) {
        // Handle 403 Forbidden error
        if (error.response && error.response.status === 403) {
            console.log('API.js | fetchDocs | 403 Forbidden: ', error.response.data.message);
            return error.response || 'API.js | fetchDocs | You are not authorized to view these documents.';
        }
        // Handle 404 no documents error (treating it as a "no documents" case)
        if (error.response && error.response.status === 404) {
            console.log('API.js | fetchDocs | 404 No Documents Found');
            return { message: 'No documents found', documents: [] }; // Returning an empty documents array
        }

        // Handle other errors
        console.error('API.js | fetchDocs | error: ', error);
        return 'An error occurred while fetching documents.';
    }
};


export const fetchOnePedigree = async (catId, currentUserId) => {
    const { data } = await http.get(`/pedigrees/${catId}`, currentUserId)
    return data
};

export const postPedigree = async (values) => {
    // console.log('API > doc.js > postPedigree > values', values)

    const { data } = await http.post(`/pedigrees`, values)

    // console.log('API > doc.js > postPedigree > data', data)

    return data
};

export const putPedigree = async (values) => {
    const data = await http.put(`/pedigrees/${values.id}`, values)
    return data
};

export const putPedigreeURL = async (pedigreeId, filePath) => {
    // console.log('api.js putPedigreeURL evoked, filePath = ', filePath)
    return await http.put(`/pedigrees/url/${pedigreeId}`, { filePath: filePath })
};



////// METRIC
export const fetchOneMetric = async (catId, currentUserId) => {
    const { data } = await http.get(`/metrics/${catId}`, currentUserId)
    return data
};

export const postMetric = async (values) => {
    const { data } = await http.post(`/metrics`, values)
    console.log('API.js | postMetric | data: ', data) //expected data = {message: 'A metric posted successfully!'}
    return data
};

export const putMetric = async (values) => {
    console.log('API.js | putMetric | values: ', values)
    return await http.put(`/metrics/${values.id}`, values) // values.id = metric id
};
////// BREEDS

import http from '../services/httpService';

export const fetchBreeds = async () => {
    const { data } = await http.get(`/breeds`);
    return data;
};

export const fetchBreedById = async (id) => {
    const { data } = await http.get(`/breeds/${id}`);
    // console.log('data from fethcBreedById data.ems = ', data.ems)
    return data;
};
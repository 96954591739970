import React, { useContext, useEffect, useState, createRef } from 'react'
import './otpinput.scss'
import { RecoveryContext } from '../IndexRestore'
import { SendRecoveryEmail } from '../../api'
import { LanguageContext } from "../../context/langContext";

const OTPInput = () => {
    const { email, otp, setPage } = useContext(RecoveryContext);
    const [timerCount, setTimer] = useState(10);
    const [disable, setDisable] = useState(true);
    const [OTPinput, setOTPinput] = useState([0, 0, 0, 0]);
    const input1 = createRef();
    const input2 = createRef();
    const input3 = createRef();
    const input4 = createRef();
    const button5 = createRef();
    const [areAllInputsFilled, setAreAllInputsFilled] = useState(false);
    const { translations } = useContext(LanguageContext)

    async function resendOTP() {
        if (disable) return;
        SendRecoveryEmail(email, otp)
            .then(() => setDisable(true))
            .then(() => alert(`A new OTP has succesfully been sent to your email ${email}`))
            .then(() => { setTimer(10) })
            .catch(console.log)
    }

    function verifyOTP() {
        if (parseInt(OTPinput.join("")) === otp) {
            setPage('reset');
            return;
        }
        console.log(parseInt(OTPinput.join("")), otp, OTPinput);
        alert("The code you have entered is not correct, try again or re-send the link");
        return;

    }
    useEffect(() => {
        let interval = setInterval(() => {
            setTimer((lastTimerCount) => {
                lastTimerCount <= 1 && clearInterval(interval);
                if (lastTimerCount <= 1) setDisable(false);
                if (lastTimerCount <= 0) return lastTimerCount;
                return lastTimerCount - 1;
            });
        }, 1000);  //each cont lasts for a second
        //cleanup the interval on complete
        return () => clearInterval(interval);
    }, [disable]);

    const handleInputChange1 = (e, nextInput) => {
        if (e.target.value.length >= e.target.maxLength) {
            nextInput.current.focus();
            setOTPinput([e.target.value, OTPinput[1], OTPinput[2], OTPinput[3]])
        }
    };
    const handleInputChange2 = (e, nextInput) => {
        if (e.target.value.length >= e.target.maxLength) {
            nextInput.current.focus();
            setOTPinput([OTPinput[0], e.target.value, OTPinput[2], OTPinput[3]])
        }
    };
    const handleInputChange3 = (e, nextInput) => {
        if (e.target.value.length >= e.target.maxLength) {
            nextInput.current.focus();
            setOTPinput([OTPinput[0], OTPinput[1], e.target.value, OTPinput[3]])
        }
    };
    const handleInputChange4 = (e, nextInput) => {
        if (e.target.value.length >= e.target.maxLength) {
            nextInput.current.focus();
            setOTPinput([OTPinput[0], OTPinput[1], OTPinput[2], e.target.value])
            setAreAllInputsFilled(true)

        }
    };

    // const handleKeyPress = (event) => {
    //     // if (event.key === 'Enter') {
    //     verifyOTP();
    //     // }
    // };

    // useEffect(() => {
    //     const areAllInputsFilled = () => {
    //         // console.log(OTPInput[3] !== '')

    //         setAreAllInputsFilled(true)
    //         // return OTPInput.forEach((value) => value.trim() !== '');
    //     };
    //     areAllInputsFilled()
    // }, OTPInput[3])


    return (
        <div className="email-varification">
            <div className="form-wrap">
                <div className="joinform">
                    <h1>{translations.restore2.q1}</h1>
                    <p>{translations.restore2.q2} {email}</p>
                    <div className="otp-input">
                        <input
                            maxLength="1"
                            type='text'
                            className="otp-input__input"
                            name=''
                            ref={input1}
                            onChange={(e) => handleInputChange1(e, input2)}
                        />
                        <input
                            maxLength="1"
                            type='text'
                            className="otp-input__input"
                            name=''
                            ref={input2}
                            onChange={(e) => handleInputChange2(e, input3)}
                        />
                        <input
                            maxLength="1"
                            type='text'
                            className="otp-input__input"
                            name=''
                            ref={input3}
                            onChange={(e) => handleInputChange3(e, input4)}
                        />
                        <input
                            maxLength="1"
                            type='text'
                            className="otp-input__input"
                            name=''
                            ref={input4}
                            onChange={(e) => handleInputChange4(e, button5)}
                        />
                    </div>
                    <button className='joinButton' ref={button5} disabled={!areAllInputsFilled} onClick={verifyOTP}>{translations.restore2.q3}</button>
                </div>
                <div>
                    <span>{translations.restore2.q4}</span> {" "}
                    {disable ? (
                        <span>{translations.restore2.q5} {timerCount}{translations.restore2.s}</span>
                    ) : (
                        <button
                            className='link-button' onClick={resendOTP}>{translations.restore2.q6}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OTPInput

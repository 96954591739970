import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import "./edit.scss"
import { useAuth } from '../../context/authContext';
import formatDateFromDB from '../../utils/formatDateFromDB';
import { formatValuesForSelect } from '../../utils/formatValuesForSelect';
import { fetchCountries, fetchUser, putUser } from '../../api';
import { debug, error } from '../../services/logger';


const Edit = () => {
  const [countries, setCountries] = useState([])
  const [currentCountrySelect, setCurrentCountrySelect] = useState('')

  const navigate = useNavigate();
  const auth = useAuth();
  const currentUserId = auth.currentUser.id;

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    birthday: '',
    site_url: '',
    social_url: '',
    about: '',
    country_id: '',
    country_en: '',
    country_ru: '',
  });


  useEffect(() => {
    const fetchCurrentUser = async () => {
      debug('Edit.js | currentUserId = ', currentUserId)
      try {
        // ------ Fetch the user data
        const userData = await fetchUser() //here is come res.data (not just res.)
        debug('Edit.js | userData = ', userData)
        // const countryValue = { "value": 643, "lable": userData.country_en }

        // ------ Format the birthday date
        const formattedDate = formatDateFromDB(userData.birthday)
        // console.log('Edit.jsx | formattedDate = ', formattedDate)

        // ------ Fetch the countries data
        const res = await fetchCountries()
        // console.log('Edit.jsx | fetchCountries | res = ', res)

        if (res) {
          const countriesForSelectEn = formatValuesForSelect(res, 'country_en');
          // console.log('Edit.jsx | countriesForSelect = ', countriesForSelectEn)
          setCountries(countriesForSelectEn); //better like this setCatSystems(dataforSelect) - without callback arraw function inside
          const userCountry = countriesForSelectEn.find(country => country.value === userData?.country_id);
          debug('userCountry from filtr = ', userCountry)
          setCurrentCountrySelect(userCountry || ''); // Set the entire object
          // Also update the 'country' field in the values state
          setValues({ ...userData, birthday: formattedDate, country_id: userCountry?.value || '' });
        }
        // console.log('countries', dataforSelect)
      } catch (error) {
        console.log(error);
      }
    }
    fetchCurrentUser()
  }, [currentUserId])

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      // console.log('values from Edit handleSubmit', values)
      await putUser(values)
      navigate('/my')
    }
    catch (err) {
      console.log(err.response);
      error("Edit.jsx | err.response in catch: ", err.response)
      // setError(err.response.data) 
    }
  }

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  };

  const handleCountryChange = (selectedOption) => {
    console.log('handleCountryChange | selectedOption: ', selectedOption)
    setCurrentCountrySelect(() => selectedOption);
    setValues((prev) => ({ ...prev, country_id: selectedOption.value }))
  }
  // our object in useState - used in the first time rendering
  const inputs = [
    {
      id: "first_name",
      name: "first_name",
      type: "text",
      placeholder: "First name",
      errorMessage: "First name should be 3-16 characters and shouldn't include any special character.",
      label: "First name*",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
      value: values.first_name,
      onChange: handleChange
    },
    {
      id: "last_name",
      name: "last_name",
      type: "text",
      placeholder: "Last name",
      errorMessage: "Last name should be 3-16 characters and shouldn't include any special character.",
      label: "Last name*",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
      value: values.last_name,
      onChange: handleChange
    },
    {
      id: "middle_name",
      name: "middle_name",
      type: "text",
      placeholder: "Middle name",
      errorMessage: "Middle name should be 3-16 characters and shouldn't include any special character.",
      label: "Middle name",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: false,
      value: values.middle_name,
      onChange: handleChange
    },
    {
      id: 4,
      name: "birthday",
      type: "date",
      placeholder: "Birthday *",
      errorMessage: "",
      label: "Birthday*",
      required: true, //without birthdate I can't send date to mysql - error.
      value: values.birthday,
      onChange: handleChange
    },
    {
      id: 5,
      name: "about",
      type: "textarea", // Change the type to 'textarea'
      placeholder: "About yourself",
      label: "About yourself",
      required: false,
      rows: 3, // Set the number of rows for the textarea
      cols: 50, // Set the number of columns for the textarea
      maxLength: 200, // limit to 200 characters
      value: values.about,
      onChange: handleChange
    },
    {
      id: 6,
      name: "site_url",
      type: "text",
      placeholder: "Your website",
      // errorMessage: "Passwords don't match.",
      label: "Your website",
      // pattern: values.password,
      required: false,
      value: values.site_url,
      onChange: handleChange
    },
    {
      id: 7,
      name: "social_url",
      type: "text",
      placeholder: "Your social media",
      // errorMessage: "Passwords don't match.",
      label: "Your social media",
      // pattern: values.password,
      required: false,
      value: values.site_url,
      onChange: handleChange
    },
    {
      id: 8,
      name: "country",
      type: "select",
      placeholder: "Country",
      // errorMessage: "Passwords don't match.",
      label: "Country you live",
      // pattern: values.password,
      required: false,
      value: currentCountrySelect,
      options: countries,
      onChange: handleCountryChange,
    },
  ]

  return (
    <div className="edit">
      <form className="edit-form" onSubmit={handleSubmit}>
        <h1>Edit Profile</h1>
        {values && (
          inputs.map((input) => (
            //valueSelect send to FormInput value as props
            <FormInput
              key={input.id}
              {...input}
            />
          ))
        )}
        <button className='joinButton'>Send</button>
        <button type='button' className='button-light' onClick={() => navigate(`/my`)}>Cancel</button>
      </form>
    </div>
  )

}

export default Edit


// const [options, setOptions] = useState([]); //array with cats (=options) to show in react - Select
// const [selectedOption, setSelectedOption] = useState(null);


// useEffect(() => {
//   const fetchAllCats = () => {
//     var headers = new Headers();
//     headers.append("X-CSCAPI-KEY", "API_KEY");

//     var requestOptions = {
//       method: 'GET',
//       headers: headers,
//       redirect: 'follow'
//     };
//     try {
//       const res =
//         fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
//           .then(response => response.text())
//           .then(result => console.log(result))
//           .catch(error => console.log('error', error));

//       // const res = await http.get("http://localhost:8800/api/cats") //we made a request to bring us info from front on this http address
//       // setCat(res.data);
//       let array = [];
//       for (let i = 0; i < res.data.length; i++) {
//         let obj = {};
//         let { id: value, cat_name: label } = res.data[i]
//         obj.value = value;
//         obj.label = label;
//         array.push(obj);
//         // console.log(obj)
//       }
//       setOptions(array);
//     } catch (error) { console.log(error) }
//   }
//   fetchAllCats()
// }, [])

//We don't show anything from DB until it's typed by user
// const loadOptions = (searchValue, callback) => {
//   setTimeout(() => {
//     const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()));
//     callback(filteredOptions)
//   }, 1000);
// }

// const handleSelectChange = (selectedOption) => {
//   setSelectedOption(selectedOption);
//   // console.log("handleSelectChange", selectedOption)
// }
